import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SMS from '../../components/anon/signin/sms'
import Password from '../../components/anon/signin/password'
import NewPassword from '../../components/anon/signin/new-password'
import Profile from '../../components/anon/signin/profile'
import PhoneConfirm from '../../components/anon/signin/phone-confirm'
import Phone from '../../components/anon/signin/phone'
import Login from '../../components/anon/signin/login'
import { userLogin, userLogout } from '../actions/auth'
import {
  getCode,
  checkRoute,
  checkCode,
  register,
  newPassword,
  clearMessage,
} from '../actions/signin'
import SMSRestore from '../../components/anon/signin/sms-restore'

export default () => {
  const state = useSelector((state) => state.signin)
  const { step, route } = state
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: 'ACCOUNTS',
      payload: undefined,
    })
  }, [])

  const nextStep = () => {
    dispatch({
      type: 'REGISTER_STEP',
      payload: state.step + 1,
    })
  }

  const prevStep = () => {
    dispatch({
      type: 'REGISTER_STEP',
      payload: state.step - 1,
    })
  }

  const clearState = () => {
    dispatch({
      type: 'REGISTER_CLEAR',
    })
  }

  const setRoute = (route) => {
    dispatch(clearMessage())
    dispatch({
      type: 'REGISTER_ROUTE',
      payload: route,
    })
  }

  const setPhone = (phone) => {
    dispatch({
      type: 'REGISTER_PHONE',
      payload: phone,
    })
  }

  const setPassword = (password) => {
    dispatch({
      type: 'REGISTER_PASSWORD',
      payload: password,
    })
  }

  const setFio = (fio) => {
    dispatch({
      type: 'REGISTER_FIO',
      payload: fio,
    })
  }

  const setEmail = (email) => {
    dispatch({
      type: 'REGISTER_EMAIL',
      payload: email,
    })
  }

  const registrationRouteStep = (step) => {
    switch (step) {
      case 1:
        return (
          <SMS
            state={state}
            clearState={clearState}
            getCode={(phone) => {
              dispatch(getCode(phone, 'registration'))
            }}
          />
        )
      case 2:
        return (
          <PhoneConfirm
            state={state}
            clearState={clearState}
            checkCode={(phone, code) => {
              dispatch(checkCode(phone, code))
            }}
          />
        )
      case 3:
        return (
          <Password
            clearState={clearState}
            state={state}
            nextStep={nextStep}
            setPassword={setPassword}
          />
        )
      case 4:
        return (
          <Profile
            clearState={clearState}
            prevStep={prevStep}
            state={state}
            setFio={setFio}
            setEmail={setEmail}
            register={(state) => {
              dispatch(register(state))
            }}
          />
        )
      default:
        return <div>1</div>
    }
  }

  const restoreRouteStep = (step) => {
    switch (step) {
      case 1:
        return (
          <SMSRestore
            state={state}
            clearState={clearState}
            getCode={(phone) => {
              dispatch(getCode(phone, 'restore'))
            }}
          />
        )
      case 2:
        return (
          <PhoneConfirm
            state={state}
            clearState={clearState}
            checkCode={(phone, code) => {
              dispatch(checkCode(phone, code))
            }}
            // setCode={code => {
            //   dispatch(setCode(code))
            // }}
          />
        )
      case 3:
        return (
          <NewPassword
            state={state}
            nextStep={nextStep}
            setPassword={setPassword}
            clearState={clearState}
            newPassword={(state) => {
              dispatch(newPassword(state))
              dispatch(userLogout())
            }}
          />
        )
      default:
        dispatch(userLogout())
        return (
          <Login
            state={state}
            setPassword={setPassword}
            setRoute={setRoute}
            clearState={clearState}
            userLogin={(name, pass) => {
              dispatch(userLogin(name, pass))
            }}
          />
        )
    }
  }

  switch (route) {
    case 'login':
      return (
        <Login
          state={state}
          setPassword={setPassword}
          setRoute={setRoute}
          clearState={clearState}
          userLogin={(name, pass) => {
            dispatch(userLogin(name, pass))
          }}
        />
      )
    case 'registration':
      return registrationRouteStep(step)
    case 'restore':
      return restoreRouteStep(step)
    default:
      return (
        <Phone
          state={state}
          checkRoute={(phone) => {
            dispatch(checkRoute(phone))
          }}
          setPhone={setPhone}
        />
      )
  }
}
