// let user = JSON.parse(localStorage.getItem('user'))
// const initialState = user ? { loggedIn: true, user } : {}

export default (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loggingIn: action.payload
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loggedIn: true,
        user: action.payload
      }
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loginFail: true
      }
    case 'LOGOUT':
      return {}
    default:
      return state
  }
}
