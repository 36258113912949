import React, { useEffect, useState, Fragment, cloneElement } from 'react'
import { useDispatch } from 'react-redux'
import { useDataProvider } from '../hooks/dataProvider'
import { isEmpty, get } from 'lodash'
import { useUser } from '../hooks/user'
import { useUid } from '../hooks/uid'
import { Redirect } from 'react-router-dom'

export default (props) => {
  const { children, open } = props
  const dataProvider = useDataProvider()
  const userName = useUser()
  const userUid = useUid()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    housesLoading: true,
    error: false,
    errorMessage: '',
    houses: [],
    houseSelected: {},
    rooms: [],
    roomSelected: {},
    roomsLoading: false,
    submitted: false,
    userName,
    userUid,
    success: false,
  })

  const setSubmitted = (value) => {
    setState({
      ...state,
      submitted: value,
    })
  }

  const setHouseSelected = (value) => {
    setState({
      ...state,
      houseSelected: value,
    })
  }

  const setRoomSelected = (value) => {
    setState({
      ...state,
      roomSelected: value,
    })
  }

  const setError = (
    isError,
    message = 'Ошибка, попробуйте повторить позже'
  ) => {
    setState({
      ...state,
      error: isError,
      errorMessage: message,
    })
  }

  useEffect(() => {
    const getHouses = () => {
      setState({ ...state, housesLoading: true })
      dataProvider
        .get(`api/houses`)
        .then((response) => {
          setState({
            ...state,
            houses: response.data,
            housesLoading: false,
          })
        })
        .catch((e) => {
          setState({ ...state, error: true, housesLoading: false })
        })
    }

    const getRooms = (house) => {
      setState({ ...state, roomsLoading: true })
      const id = get(house, 'nid')
      dataProvider
        .get(`api/house/accounts/${id}?_format=json`)
        .then((response) => {
          setState({
            ...state,
            rooms: response.data,
            roomsLoading: false,
            roomSelected: {},
          })
        })
        .catch((e) => {
          setState({ ...state, error: true, roomsLoading: false })
        })
    }

    const createUserAccountConnection = () => {
      dataProvider
        .post(`jsonapi/node/user_account_connector`, {
          data: {
            type: 'node--user_account_connector',
            attributes: {
              title: `${userName} - ${state.roomSelected.title}`,
              field_connector_status: 'accepted',
            },
            relationships: {
              field_account: {
                data: {
                  type: 'node--account',
                  id: state.roomSelected.uuid,
                },
              },
              field_user: {
                data: {
                  type: 'user--user',
                  id: userUid,
                },
              },
            },
          },
        })
        .then((response) => {
          setState({
            ...state,
            success: true,
          })
          dispatch({
            type: 'ACCOUNTS',
            payload: undefined,
          })
        })
        .catch((e) => {
          setState({ ...state, error: true, roomsLoading: false })
        })
    }

    if (open && !state.houses.length) {
      getHouses()
    }
    if (!isEmpty(state.houseSelected)) {
      getRooms(state.houseSelected)
    }
    if (state.submitted) {
      createUserAccountConnection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, state.houseSelected, state.submitted])

  return (
    <Fragment>
      {state.success ? (
        <Redirect to='/' />
      ) : (
        cloneElement(children, {
          state,
          setState,
          setHouseSelected,
          setRoomSelected,
          setError,
          setSubmitted,
        })
      )}
    </Fragment>
  )
}
