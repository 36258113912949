import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDataProvider } from '../hooks/dataProvider'
import { useParams } from 'react-router-dom'
import _, { get, find, groupBy, nth, keys, isEmpty, isUndefined } from 'lodash'
import moment from 'moment'
import { monthsSmall, monthsSmallIn } from '../tools/months'
import { formatMoney, requestTimeLimit, round } from '../tools/tools'

export default () => {
  const dataProvider = useDataProvider()
  const { id } = useParams()
  const lastBill = useSelector((state) => state.account.lastBill)
  const [currMonthPays, setCurrMonthPays] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await dataProvider.get(
        `jsonapi/node/bill` +
        `?filter[field_account.id][value]=${id}` +
        `&include=field_service` +
        `&sort=-field_date`
      )
      const data = get(response, 'data.data', {})
      const included = get(response, 'data.included', {})
      const normalized = data.map((item) => {
        const serviceId = get(item, 'relationships.field_service.data.id')
        const field_service = find(included, { id: serviceId })
        item.included = {
          field_service,
        }
        return item
      })
      const groupped = groupBy(normalized, 'attributes.field_date')
      const months = keys(groupped)

      const monthKey = nth(months, 0)
      let monthName
      let monthNameNext
      if (!isUndefined(monthKey)) {
        monthName = nth(monthsSmall, moment(monthKey).format('MM') - 1)
        monthNameNext = nth(monthsSmallIn, moment(monthKey).add(1, 'months').format('MM') - 1)
      }
      else {
        monthName = nth(monthsSmall, moment().subtract(1, 'months').format('MM') - 1)
        monthNameNext = nth(monthsSmallIn, moment().format('MM') - 1)
      }

      const bill = get(groupped, monthKey, [])
      let date = ''
      let balanceStart = 0
      let balanceEnd = 0
      let accrued = 0
      let penalties = 0
      let penaltiesStart = 0
      bill.map((item) => {
        date = get(item, 'attributes.field_date', 0)
        balanceEnd += parseFloat(get(item, 'attributes.field_balance_end', 0))
        accrued +=
          parseFloat(get(item, 'attributes.field_accrued', 0)) +
          parseFloat(get(item, 'attributes.field_recalculation', 0))
        balanceStart += parseFloat(
          get(item, 'attributes.field_balance_start', 0)
        )
        penalties +=
          parseFloat(get(item, 'attributes.field_penalties_accrued', 0)) +
          parseFloat(get(item, 'attributes.field_penalties_recalculation', 0))
        penaltiesStart +=
          parseFloat(get(item, 'attributes.field_penalties_balance_start', 0))
        return true
      })

      const result = {
        bill,
        monthName,
        monthNameNext,
        date,
        balanceStart,
        accrued,
        penalties,
        penaltiesStart,
        accruedFormated: formatMoney(round(accrued + penalties)),
        balanceEnd,
        requestTime: +new Date(),
      }
      dispatch({
        type: 'ACCOUNT_LAST_BILL',
        payload: result,
      })
    }

    if (isEmpty(lastBill) || +new Date() - lastBill.requestTime > requestTimeLimit) {
      fetchData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function lastMonthPayments() {
      // You can await here
      const date = moment(lastBill.date).add(1, 'month').format('YYYY-MM-01')
      const response = await dataProvider.get(
        `jsonapi/node/payment_service` +
        `?filter[date][condition][path]=field_date` +
        `&filter[date][condition][operator]=>=` +
        `&filter[date][condition][value]=${date}` +
        `&filter[field_account.id][value]=${id}` +
        `&sort=-field_date`
      )

      const data = []
      _.forEach(get(response, 'data.data', {}), (item) => {
        const targetItem = find(data, ['attributes.field_date', item.attributes.field_date])
        const paid = parseFloat(item.attributes.field_paid)
        const penalties_paid = parseFloat(item.attributes.field_penalties_paid)

        if (!targetItem) {
          item.attributes.field_paid = paid
          item.attributes.field_penalties_paid = penalties_paid
          data.push(item)
        }
        else {
          targetItem.attributes.field_paid += paid
          targetItem.attributes.field_penalties_paid += penalties_paid
        }
      })

      let totalPayment = 0
      data.map((item) => {
        const paid = round(get(item, 'attributes.field_paid', 0))
        const penPaid = get(item, 'attributes.field_penalties_paid', 0)
        totalPayment += paid + penPaid
        return totalPayment
      })
      setCurrMonthPays(totalPayment)
    }
    if (!isEmpty(lastBill) && isEmpty(currMonthPays)) {
      lastMonthPayments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastBill])

  useEffect(() => {
    if (lastBill && !isUndefined(currMonthPays)) {
      let debt = lastBill.balanceEnd - lastBill.accrued
      let toPay =
        lastBill.balanceEnd -
        currMonthPays +
        lastBill.penalties

      dispatch({
        type: 'ACCOUNT_TO_PAY',
        payload: {
          currMonthPays: formatMoney(round(currMonthPays)),
          debt: debt,
          toPaySum: round(toPay),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastBill, currMonthPays])

  return <></>
}
