import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Box, Collapse } from '@material-ui/core'
import { Formik, Form } from 'formik'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../ui/dialog-title'
import TextField from '../ui/textfield'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as Yup from 'yup'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => {
  return {
    link: {
      textDecoration: 'none'
    },
    bold: {
      fontWeight: 'bold'
    }
  }
})

const schema = Yup.object().shape({
  subject: Yup.string().required('Заполние это поле'),
  message: Yup.string().required('Заполние это поле')
})

export default function AddAppealForm(props) {
  const classes = useStyles()

  const { open, setOpen, state, setState } = props
  const { fio, account } = state
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      fullWidth
    >
      <DialogTitle onClose={handleClose}>Электронное обращение</DialogTitle>
      <DialogContent>
        {state.success ? (
          <Box mb={3}>
            <Typography>
              Ваше обращение поступило в работу. В самое ближайшее время наш
              специалист вам ответит.
            </Typography>
          </Box>
        ) : (
          <Formik
            initialValues={{
              fio: fio,
              account: account,
              subject: '',
              message: ''
            }}
            validationSchema={schema}
            onSubmit={(values, actions) => {
              setState({
                ...state,
                values,
                result: {
                  type: '',
                  message: ''
                },
                actions,
                submitToggle: !state.submitToggle
              })
            }}
          >
            {({ values, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                {state.message.type && (
                  <Collapse in={true}>
                    <Box mb={3}>
                      <Alert
                        className={classes.alert}
                        variant='outlined'
                        severity={state.message.type}
                      >
                        {state.message.message}
                      </Alert>
                    </Box>
                  </Collapse>
                )}
                {isSubmitting && (
                  <Box mb={3}>
                    <LinearProgress />
                  </Box>
                )}
                <Box mb={3}>
                  <Typography>Пожалуйста, заполните все поля формы.</Typography>
                </Box>
                <Box mb={3}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    label='Лицевой счёт'
                    name='account'
                    disabled
                    value={values.account}
                    autoComplete='off'
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    label='ФИО'
                    name='fio'
                    value={values.fio}
                    disabled
                    autoComplete='off'
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    label='Тема'
                    name='subject'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subject}
                    autoComplete='off'
                  />
                </Box>
                <Box mb={3}>
                  <TextField
                    multiline
                    rows='5'
                    rowsMax='5'
                    name='message'
                    label='Сообщение'
                    fullWidth
                    variant='outlined'
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Box>
                {/* <Box mb={1}>
                  <Box mb={1}>
                    <Typography align='left' variant='body1'>
                      Файлы:
                    </Typography>
                    <Typography align='left' variant='body2'>
                      Вы можете прикрепить до 5 файлов размером до 5Мб
                    </Typography>
                  </Box>
                  {state.files.map((value, key) => {
                    return (
                      <Typography align='left' key={key}>
                        {value.name}
                      </Typography>
                    )
                  })}
                </Box>
                <Box mb={3}>
                  <Input
                    accept='image/*'
                    className={classes.input}
                    style={{ display: 'none' }}
                    id='button-file'
                    type='file'
                    onChange={event => {
                      const file = get(event, 'target.files[0]')
                      if (!file) {
                        return
                      }
                      const reader = new FileReader()
                      reader.readAsArrayBuffer(file)
                      reader.onload = fileContent => {
                        uploadFile(file, fileContent)
                      }
                    }}
                  />
                  <label htmlFor='button-file'>
                    <Button
                      variant='outlined'
                      component='span'
                      className={classes.button}
                    >
                      Добавить файл
                    </Button>
                  </label>
                </Box> */}
                <DialogActions>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    type='submit'
                    disabled={isSubmitting}
                  >
                    Отправить
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    variant='outlined'
                    color='primary'
                    size='large'
                  >
                    Отменить
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  )
}

AddAppealForm.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setState: PropTypes.func,
  state: PropTypes.object,
  uploadFile: PropTypes.func,
}