import { applyMiddleware, createStore } from 'redux'
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from './reducer'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'

export default storage => {
  const persistConfig = {
    key: 'root',
    storage
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  // Build the middleware for intercepting and dispatching navigation actions
  const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
      return applyMiddleware(thunk)
    } else {
      // Enable additional logging in non-production environments.
      return applyMiddleware(thunk, logger)
    }
  }

  const store = createStore(
    persistedReducer,
    composeWithDevTools(getMiddleware())
  )

  let persistor = persistStore(store)

  return { store, persistor }
}
