import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  }
}))

export default function Header(props) {
  const classes = useStyles()
  return <div className={classes.root}>{props.children}</div>
}

Header.propTypes = {
  children: PropTypes.node
}