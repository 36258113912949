import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import Logo from '../core/images/logo-full.js'
import LogoM from '../core/images/logo-m.js'
import Hidden from '@material-ui/core/Hidden'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  colLogo: {
    flexGrow: 1,
    alignSelf: 'center'
  },
  appbar: {
    backgroundColor: '#fff',
    color: '#000'
  },
  logo: {
    width: '230px',
    display: 'flex'
  },
  logoM: {
    display: 'flex'
  },
  logout: {
    transition: 'color 0.4s',
    letterSpacing: '0.5px',
    textAlign: 'center',
    padding: '20px 25px'
  },
  contact: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '1.15',
    fontWeight: 300,
    letterSpacing: '0.5px',
    padding: '20px 25px',
    borderLeft: 'solid 1px #e5e5e5'
  },
  accounts: {
    textAlign: 'center',
    padding: '20px 25px',
    borderRight: 'solid 1px #e5e5e5',
    borderLeft: 'solid 1px #e5e5e5'
  },
  link: {
    color: '#5F8FC1',
    textDecoration: 'none'
  },
  'link:hover': {
    color: '#014E86'
  }
}))

const MyAppBar = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AppBar
        classes={{
          root: classes.appbar
        }}
        position='static'
      >
        <Toolbar>
          <div className={classes.root}>
            <div className={classes.colLogo}>
              <Hidden xsDown>
                <Box className={classes.logo}>
                  <Logo />
                </Box>
              </Hidden>
              <Hidden smUp>
                <Box className={classes.logoM}>
                  <LogoM />
                </Box>
              </Hidden>
            </div>
            <Hidden smDown>
              <div className={classes.contact}>
                <Typography>Контакт-центр 24/7 8 (800) 500 777 9</Typography>
              </div>
            </Hidden>
            <div className={classes.accounts}>
              <Link to='/' className={classes.link}>
                Мои&nbsp;адреса
              </Link>
            </div>
            <div className={classes.logout}>
              <Link to='/logout' className={classes.link}>
                Выйти
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default MyAppBar
