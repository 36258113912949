import React from 'react'
import { useSelector } from 'react-redux'
import Preloader from '../preloader'
import {
  Box,
  Fade,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { get } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { formatMoney } from '../../core/tools/tools'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(4),
    width: '100%',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  tableRow: {
    backgroundColor: 'inherit',
    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}))

export default () => {
  const bill = useSelector((state) => get(state, 'account.lastBill.bill', {}))
  const monthName = useSelector((state) =>
    get(state, 'account.lastBill.monthName', 0)
  )

  const classes = useStyles()
  const totals = {
    balance_start: 0,
    accrued: 0,
    penalties: 0,
    paid: 0,
    balance_end: 0,
  }
  const loading = !monthName
  return (
    <Box className={classes.root}>
      {loading ? (
        <Preloader />
      ) : (
        <Fade in={!loading}>
          <Box>
            <Box>
              <Typography className={classes.header} variant='h6'>
                Начислено за {monthName}
              </Typography>
            </Box>
            {bill.length ? (
              <Table>
                <TableBody>
                  {bill.map((row) => {
                    const accrued =
                      parseFloat(get(row, 'attributes.field_accrued', 0)) +
                      parseFloat(
                        get(row, 'attributes.field_recalculation', 0)
                      )
                    totals.accrued += accrued

                    const penalties =
                      parseFloat(
                        get(row, 'attributes.field_penalties_accrued', 0)
                      ) +
                      parseFloat(
                        get(row, 'attributes.field_penalties_recalculation', 0)
                      )
                    totals.penalties += penalties
                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={get(row, 'id')}
                      >
                        <TableCell component='th' scope='row'>
                          {get(row, 'included.field_service.attributes.title')}
                        </TableCell>
                        <TableCell align='right' component='th' scope='row'>
                          {formatMoney(accrued)}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  <TableRow key={'12345'}>
                    <TableCell
                      component='th'
                      scope='row'
                    >
                      Пени
                    </TableCell>
                    <TableCell
                      align='right'
                      component='th'
                      scope='row'
                    >
                      {formatMoney(totals.penalties)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={'12354'}>
                    <TableCell
                      className={classes.tableHead}
                      component='th'
                      scope='row'
                    >
                      Итого
                    </TableCell>
                    <TableCell
                      align='right'
                      className={classes.tableHead}
                      component='th'
                      scope='row'
                    >
                      {formatMoney(totals.accrued + totals.penalties)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <Typography>Начисления не произведены</Typography>
            )}
          </Box>
        </Fade>
      )}
    </Box>
  )
}
