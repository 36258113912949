import React from 'react'
import Preloader from '../preloader'
import { Box, Fade, Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LastBillTable from './last-bill-table'
import LastBillSummary from './last-bill-summary'
import LastBill from '../../core/components/last-bill'
import Bills from '../../core/components/bills'
import BillsView from '../bills/bills-block'
import Payments from '../../core/components/payments'
import PaymentsView from '../payments/payments-block'

const useStyles = makeStyles((theme) => ({
  leftCol: {
    borderRight: 'solid 1px',
    borderRightColor: theme.palette.grey[300],
  },
  second: {
    marginTop: theme.spacing(4),
  },
  box: {
    position: 'relative',
    padding: theme.spacing(4),
    width: '100%',
  },
}))

export default (props) => {
  const classes = useStyles()

  return (
    <Box>
      {false ? (
        <Preloader />
      ) : (
        <Fade in={!false}>
          <Box>
            <Paper square>
              <Grid container>
                <Grid item xs={12} lg={4} className={classes.leftCol}>
                  <LastBill />
                  <LastBillSummary />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <LastBillTable />
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.second} square>
              <Grid container>
                <Grid item xs={12} lg={8} className={classes.leftCol}>
                  <Box className={classes.box}>
                    <Bills>
                      <BillsView />
                    </Bills>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box className={classes.box}>
                    <Payments>
                      <PaymentsView />
                    </Payments>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      )}
    </Box>
  )
}
