import { dataProvider } from '../tools/data-provider'
import { useToken } from './token'
import settings from '../constants/settings'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

export const useDataProvider = headers => {
  const token = useToken()
  const csrfToken = useSelector(state => get(state, 'auth.user.csrf_token'))
  const { baseURL } = settings

  const defaultHeaders = {
    Accept: 'application/vnd.api+json; charset=utf-8',
    'Content-Type': 'application/vnd.api+json; charset=utf-8',
    Authorization: 'Bearer ' + token,
    'X-CSRF-Token': csrfToken,
  }

  const config = {
    token,
    baseURL,
    headers: headers || defaultHeaders,
  }
  return dataProvider(config)
}
