import React, { Fragment, useState } from 'react'
import Button from '../../ui/button'
import TextField from '@material-ui/core/TextField'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import InputMask from 'react-input-mask'
import LinearProgress from '@material-ui/core/LinearProgress'
import Header from '../header'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  header: {
    marginBottom: theme.spacing(1)
  }
}))

export default function PhoneConfirm({ state, checkCode, clearState }) {
  const { phone, loading, message } = state
  const [code, setCode] = useState('')
  const classes = useStyles()

  const onSubmit = event => {
    event.preventDefault()
    const shortPhone = phone.replace(/\D+/g, '')
    const shortCode = code.replace(/\D+/g, '')
    checkCode(shortPhone, shortCode)
  }

  return (
    <Fragment>
      <Header message={message} />
      <form onSubmit={onSubmit} className={classes.form} noValidate>
        {loading && <LinearProgress />}
        <Typography variant='h5' className={classes.header}>
          Введите код из SMS
        </Typography>
        <Typography variant='body1'>
          Код придет в течение 2 минут на номер:
          <br />
          {phone}{' '}
          <a
            href='#change'
            onClick={e => {
              e.preventDefault()
              clearState()
            }}
          >
            Изменить номер
          </a>
        </Typography>

        <InputMask
          mask='9 9 9 9'
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='code'
          label='Код подтверждения'
          name='code'
          autoFocus
          value={code}
          onChange={e => setCode(e.target.value)}
        >
          {inputProps => <TextField {...inputProps} />}
        </InputMask>
        <Box className={classes.wrapper}>
          <Button className={classes.submit} disabled={loading}>
            Подтвердить
          </Button>
        </Box>
      </form>
    </Fragment>
  )
}

PhoneConfirm.propTypes = {
  state: PropTypes.object.isRequired,
  checkCode: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired
}