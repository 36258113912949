import main from './reducers/main'
import auth from './reducers/auth'
import loading from './reducers/loading'
import signin from './reducers/signin'
import houses from './reducers/houses'
import account from './reducers/account'
import accounts from './reducers/accounts'
import { combineReducers } from 'redux'

export default combineReducers({
  main,
  auth,
  loading,
  signin,
  houses,
  account,
  accounts
})
