import React, { Fragment } from 'react'
import Button from '../../ui/button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Header from '../header'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  wrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  notice: {
    textAlign: 'center',
  },
}))

export default function Sms({ state, getCode, clearState }) {
  const { phone, message, loading } = state

  const classes = useStyles()
  const onSubmit = (e) => {
    e.preventDefault()
    const shortPhone = phone.replace(/\D+/g, '')
    getCode(shortPhone)
  }

  return (
    <Fragment>
      <Header message={message} />
      <form onSubmit={onSubmit} className={classes.form} noValidate>
        {loading && <LinearProgress />}
        <Typography variant='h5' className={classes.header}>
          Вы — новый пользователь
          <br /> личного кабинета
        </Typography>
        <Typography variant='body1' className={classes.text}>
          Для регистрации мы отправим код подтверждения на номер
          <br />
          {phone}{' '}
          <a
            href='#change'
            onClick={(e) => {
              e.preventDefault()
              clearState()
            }}
          >
            Изменить номер
          </a>
        </Typography>
        <Box className={classes.wrapper}>
          <Button className={classes.submit} disabled={loading}>
            Получить код
          </Button>
        </Box>
        <Typography variant='body1' className={[classes.text, classes.notice]}>
          Нажимая кнопку "Получить код", вы соглашаетесь на обработку &nbsp;
          <a
            href='https://dmhzn.com/policy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Персональных данных.
          </a>
        </Typography>
      </form>
    </Fragment>
  )
}

Sms.propTypes = {
  state: PropTypes.object.isRequired,
  getCode: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired
}