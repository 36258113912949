import { dataProvider } from '../tools/data-provider'
import settings from '../constants/settings'
import { userLogin } from './auth'

const { baseURL } = settings
const config = { baseURL, headers: {} }

export const clearMessage = () => {
  return {
    type: 'REGISTER_MESSAGE',
    payload: {
      text: '',
      status: '',
    },
  }
}

export const checkCode = (phone, code) => {
  return (dispatch) => {
    dispatch(clearMessage())
    dispatch({ type: 'REGISTER_LOADING', payload: true })
    dataProvider(config)
      .get(`phone-confirm/verify-sms/${phone}/${code}`)
      .then((response) => {
        const codeMatch = response.data.codeMatch
        if (codeMatch) {
          dispatch({
            type: 'REGISTER_CODE',
            payload: code,
          })
          dispatch({
            type: 'REGISTER_NEXT_STEP',
          })
        } else {
          dispatch({
            type: 'REGISTER_MESSAGE',
            payload: {
              text: 'Введённый код неверен или время его действия истекло',
              status: 'error',
            },
          })
        }
      })
      .catch((e) => {
        dispatch({
          type: 'REGISTER_MESSAGE',
          payload: {
            text: '',
            status: 'error',
          },
        })
      })
      .finally(() => {
        dispatch({ type: 'REGISTER_LOADING', payload: false })
      })
  }
}

export const getCode = (phone, variant = '') => {
  return (dispatch) => {
    dispatch({ type: 'REGISTER_LOADING', payload: true })
    dataProvider(config)
      .get(`phone-confirm/send-sms/${phone}/${variant}`)
      .then((response) => {
        dispatch({
          type: 'REGISTER_NEXT_STEP',
        })
      })
      .catch((e) => {
        dispatch({
          type: 'REGISTER_MESSAGE',
          payload: {
            text: '',
            status: 'error',
          },
        })
      })
      .finally(() => {
        dispatch({ type: 'REGISTER_LOADING', payload: false })
      })
  }
}

export const checkRoute = (phone) => {
  return (dispatch) => {
    dispatch({ type: 'REGISTER_LOADING', payload: true })
    dataProvider(config)
      .get(`user-exist/${phone}`)
      .then((response) => {
        const route = response.data.userExist ? 'login' : 'registration'
        dispatch({
          type: 'REGISTER_ROUTE',
          payload: route,
        })
      })
      .catch((e) => {
        dispatch({
          type: 'REGISTER_MESSAGE',
          payload: {
            text: e.message,
            status: 'error',
          },
        })
      })
      .finally(() => {
        dispatch({ type: 'REGISTER_LOADING', payload: false })
      })
  }
}

export const newPassword = (state) => {
  return (dispatch) => {
    dispatch({ type: 'REGISTER_LOADING', payload: true })
    dataProvider(config)
      .post(`api/password-change?_format=json`, {
        phone: state.phone.replace(/\D+/g, ''),
        pass: state.password,
        code: state.code,
      })
      .then((response) => {
        dispatch({
          type: 'REGISTER_STEP',
          payload: '1',
        })
        dispatch({
          type: 'REGISTER_ROUTE',
          payload: 'login',
        })
        dispatch({
          type: 'REGISTER_MESSAGE',
          payload: {
            text: 'Ваш пароль изменён.',
            status: 'info',
          },
        })
      })
      .catch((e) => {
        dispatch({
          type: 'REGISTER_MESSAGE',
          payload: {
            text: '',
            status: 'error',
          },
        })
      })
      .finally(() => {
        dispatch({ type: 'REGISTER_LOADING', payload: false })
      })
  }
}

export const register = (state) => {
  return (dispatch) => {
    dispatch({ type: 'REGISTER_LOADING', payload: true })
    dataProvider(config)
      .post(`user/register?_format=json`, {
        name: [
          {
            value: state.phone.replace(/\D+/g, ''),
          },
        ],
        mail: [
          {
            value: state.email,
          },
        ],
        pass: [
          {
            value: state.password,
          },
        ],
        field_fio: [
          {
            value: state.fio,
          },
        ],
      })
      .then((response) => {
        dispatch(userLogin(state.phone.replace(/\D+/g, ''), state.password))
      })
      .catch((e) => {
        dispatch({
          type: 'REGISTER_MESSAGE',
          payload: {
            text: '',
            status: 'error',
          },
        })
      })
      .finally(() => {
        dispatch({ type: 'REGISTER_LOADING', payload: false })
      })
  }
}
