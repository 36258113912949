import React from 'react'
import { Redirect } from 'react-router-dom'
import { userLogout } from '../actions/auth'
import { useDispatch } from 'react-redux'

const Logout = () => {
  const dispatch = useDispatch()
  dispatch(userLogout())
  return (
    <Redirect
      to={{
        pathname: '/'
      }}
    />
  )
}

export default Logout
