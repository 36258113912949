import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Alert from '@material-ui/lab/Alert'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.secondary.main
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  alert: {
    width: '100%',
    boxSizing: 'border-box'
  }
}))

const Header = props => {
  const classes = useStyles()
  const {
    message: { status, text = 'Ошибка, попробуйте повторить позже' }
  } = props
  return (
    <Box style={{ width: '100%' }}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      {status && (
        <Alert className={classes.alert} variant='outlined' severity={status}>
          {text}
        </Alert>
      )}
    </Box>
  )
}

Header.propTypes = {
  message: PropTypes.object
}

export default Header