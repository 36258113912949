import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'

export default function TextFieldComponent({ field, form, ...props }) {
  return (
    <TextField
      variant='outlined'
      margin='normal'
      fullWidth
      {...field}
      {...props}
    />
  )
}

TextFieldComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  fullWidth: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  rows: PropTypes.string,
  rowsMax: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  variant: PropTypes.string.isRequired,
}