import React, { Fragment } from 'react'
import Preloader from '../preloader'
import {
  Box,
  Fade,
  Select,
  MenuItem,
  Typography,
  TableRow,
  Table,
  TableBody,
  TableCell,
  Paper,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { get, map, isEmpty, isUndefined, assignWith } from 'lodash'
import { formatMoney, formatDate } from '../../core/tools/tools'
import Chart from '../chart'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {},
  select: {
    paddingBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  tableRow: {
    backgroundColor: 'inherit',
    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  col1: {
    padding: theme.spacing(3),
  },
  col2: {
    padding: theme.spacing(3),
  },
}))

export default function PaymentsPage({ data = {}, years, year, setYear, tab, setTab, lastMonthDate }) {
  const { payments, totals } = data
  const loadingData = isEmpty(data)

  const lastDate = new Date(lastMonthDate)

  const modifiedTotals = {}
  assignWith(modifiedTotals, totals, (_, srcValue, key) => {
    const date = new Date(key)
    return date > lastDate && srcValue == 0 ? null : srcValue
  })

  if (tab !== 2) {
    setTab(2)
  }
  const classes = useStyles()
  return (
    <Fragment>
      {isUndefined(years) ? (
        <Preloader />
      ) : (
        <Fade in={!isUndefined(years)}>
          <Paper>
            {isEmpty(years) ? (
              <Grid container>
                <Grid item xs={12} md={6} lg={7} className={classes.col1}>
                  <Box className={classes.header}>
                    <Box>
                      <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                        История платежей отсутствует
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12} md={6} lg={7} className={classes.col1}>
                  {loadingData ? (
                    <Preloader />
                  ) : (
                    <Fade in={!loadingData}>
                      <Chart data={modifiedTotals} />
                    </Fade>
                  )}
                </Grid>
                <Grid item xs={12} md={6} lg={5} className={classes.col2}>
                  <Box>
                    <Box className={classes.header}>
                      <Box>
                        <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                          История платежей
                        </Typography>
                      </Box>

                      <Box className={classes.select}>
                        <Select
                          label='Год'
                          id='year-select'
                          variant='outlined'
                          value={year}
                          onChange={(e) => {
                            setYear(e.target.value)
                          }}
                        >
                          {years.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item} год
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                    {loadingData ? (
                      <Preloader />
                    ) : (
                      <Fade in={!loadingData}>
                        <Table
                          className={classes.table}
                          aria-label='simple table'
                        >
                          <TableBody>
                            {map(payments, (payment, key) => {
                              return (
                                <TableRow
                                  className={classes.tableRow}
                                  key={key}
                                >
                                  <TableCell>
                                    {formatDate(
                                      get(payment, 'attributes.field_date')
                                    )}
                                  </TableCell>
                                  <TableCell align='right'>
                                    {formatMoney(
                                      get(payment, 'attributes.field_paid') +
                                      get(payment, 'attributes.field_penalties_paid')
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </Fade>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Fade>
      )}
    </Fragment>
  )
}

PaymentsPage.propTypes = {
  data: PropTypes.object,
  years: PropTypes.array,
  year: PropTypes.string,
  setYear: PropTypes.func,
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  lastMonthDate: PropTypes.string
}