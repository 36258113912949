import numeral from 'numeral'
import 'numeral/locales/ru'
import moment from 'moment'

export const formatMoney = sum => {
  return numeral(parseFloat(sum)).format('0,0.00') + ' ₽'
}

export const formatFloat = sum => {
  numeral.locale('ru')
  return numeral(parseFloat(sum)).format('0[.]00')
}

export const formatDate = date => {
  return moment(date).format('DD.MM.YYYY')
}

export const round = float => {
  return Math.round(float * 100) / 100
}

export const capitalize = word => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const requestTimeLimit = 1 * 3600 * 1000 // час в миллсекунды
