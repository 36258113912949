import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import jwt_decode from 'jwt-decode'

export const useToken = () => {
  const auth = useSelector((state) => state.auth)
  const token = get(auth, 'user.access_token')
  const dispatch = useDispatch()

  if (token) {
    const decoded = jwt_decode(token)
    if (Date.now() < get(decoded, 'exp', 0) * 1000) {
      return token
    } else {
      dispatch({ type: 'REGISTER_CLEAR' })
      dispatch({ type: 'LOGOUT' })
    }
  }

  return false
}
