import React from 'react'
import { Typography, Box, Grid, TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import map from 'lodash/map'
import set from 'lodash/set'
import get from 'lodash/get'
import { formatFloat } from '../../core/tools/tools'
import PropTypes from 'prop-types'

const ipuDays = {
  start: 12,
  end: 22,
}

const useStyles = makeStyles((theme) => ({
  panelRoot: {
    boxShadow: 'none',
  },
  heading: {
    fontWeight: 'bold',
  },
  paperRoot: {
    padding: theme.spacing(3),
  },
}))

export default function IpusForm({
  ipuLastValues,
  setIpuLastValue,
  isSubmitting,
  setSubmitting,
}) {
  let disabled = false
  const date = new Date()
  if (date.getDate() < ipuDays.start || date.getDate() > ipuDays.end) {
    disabled = true
  }
  const classes = useStyles()

  return (
    <Box>
      <Grid container alignContent='center' justify='center'>
        <Grid item xs={12} sm={10} md={8} lg={6} className={classes.col1}>
          {disabled && (
            <Typography align='center' className={classes.header} variant='h6'>
              Показания передаются с {ipuDays.start} по {ipuDays.end} число
            </Typography>
          )}
          {map(ipuLastValues, (value) => {
            const ipu = value.ipu
            return (
              <Box
                key={ipu.id}
                style={{
                  display: 'flex',
                  marginTop: '1em',
                }}
              >
                <Box
                  style={{
                    marginRight: '1em',
                    flexGrow: 1,
                  }}
                >
                  <Typography>{`${get(
                    ipu,
                    'attributes.field_text_1'
                  )}, номер счётчика`}</Typography>
                  <TextField
                    variant='outlined'
                    id={ipu.id}
                    value={get(ipu, 'attributes.field_counter_number')}
                    fullWidth
                    disabled
                  />
                </Box>
                <Box
                  style={{
                    maxWidth: '120px',
                  }}
                >
                  <Typography>Показание</Typography>
                  <TextField
                    variant='outlined'
                    id={ipu.id}
                    type='number'
                    fullWidth
                    value={formatFloat(get(value, 'newValue'))}
                    onChange={(e) => {
                      setIpuLastValue(
                        set({}, ipu.id, {
                          newValue: e.target.value,
                        })
                      )
                    }}
                    disabled={isSubmitting || disabled}
                  />
                </Box>
              </Box>
            )
          })}
          <Button
            disabled={isSubmitting || disabled}
            style={{
              display: 'flex',
              marginLeft: 'auto',
              marginTop: '1em',
            }}
            variant='contained'
            color='primary'
            onClick={() => {
              setSubmitting(true)
            }}
          >
            Отправить
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

IpusForm.propTypes = {
  ipuLastValues: PropTypes.object,
  setIpuLastValue: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setSubmitting: PropTypes.func
}