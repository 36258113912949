const initialState = {
  houses: [],
  loading: false,
  error: false,
  errorMessage: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'HOUSES_SET':
      return {
        ...state,
        houses: action.payload
      }
    case 'HOUSES_LOADING':
      return {
        ...state,
        loading: action.payload
      }
    case 'HOUSES_ERROR':
      return {
        ...state,
        error: action.payload
      }
    case 'HOUSES_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.payload
      }
    default:
      return state
  }
}
