import React from 'react'
import Preloader from '../preloader'
import { Box, Fade, Select, MenuItem, Typography } from '@material-ui/core'
import { map, isEmpty, isUndefined } from 'lodash'
import Panel from './panel'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  select: {
    paddingBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
}))

export default function BillsBlock({ data = {}, years, year, setYear }) {
  const { months } = data
  const classes = useStyles()
  let panelIndex = 0
  const loadingData = isEmpty(data)
  return (
    <>
      {isUndefined(years) ? (
        <Preloader />
      ) : (
        <Fade in={!isUndefined(years)}>
          {isEmpty(years) ? (
            <Box>
              <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                История начислений отсутствует
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box className={classes.header}>
                <Box>
                  <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                    История начислений
                  </Typography>
                </Box>
                <Box className={classes.select}>
                  {!isEmpty(year) && (
                    <Select
                      label='Год'
                      id='year-select'
                      variant='outlined'
                      value={year}
                      onChange={(e) => {
                        setYear(e.target.value)
                      }}
                    >
                      {years.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item} год
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
              </Box>
              {loadingData ? (
                <Preloader />
              ) : (
                <Fade in={!loadingData}>
                  <Box>
                    {map(months, (month, key) => {
                      return (
                        <Panel
                          key={key}
                          date={key}
                          month={month}
                          index={panelIndex++}
                        ></Panel>
                      )
                    })}
                  </Box>
                </Fade>
              )}
            </Box>
          )}
        </Fade>
      )}
    </>
  )
}

BillsBlock.propTypes = {
  data: PropTypes.object,
  years: PropTypes.array,
  year: PropTypes.string,
  setYear: PropTypes.func
}