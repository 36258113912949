import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Container, Box } from '@material-ui/core'
import Background from '../../core/images/moscow-1.jpeg'
import { Switch, Route } from 'react-router-dom'
import Logo from '../../core/images/logo-full.js'
import Signin from '../../core/components/signin'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    paddingTop: theme.spacing(8),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardContent: {
    padding: theme.spacing(4),
  },
}))

export default () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Container component='main' maxWidth='sm'>
        <Paper className={classes.cardContent}>
          <Box className={classes.paper}>
            <Logo />
            {0 ? (
              <Box
                style={{
                  margin: '40px 0',
                  fontSize: '20px',
                }}
              >
                В личном кабинете проводятся технические работы.
              </Box>
            ) : (
              <Switch>
                <Route path='/signin'>
                  <Signin />
                </Route>
              </Switch>
            )}
          </Box>
        </Paper>
      </Container>
    </Box>
  )
}
