import React, { useEffect, useState, cloneElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDataProvider } from '../hooks/dataProvider'

export default ({ children }) => {
  // const deleteAccount = useSelector(state => state.main.deleteAccount)
  // console.log('deleteAccount', deleteAccount)
  const [deleteAccount, setDeleteAccount] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dataProvider = useDataProvider()
  const dispatch = useDispatch()
  useEffect(() => {
    if (deleteAccount) {
      setIsSubmitting(true)
      dataProvider
        .delete(`jsonapi/node/user_account_connector/${deleteAccount}`)
        .then(response => {
          setIsSubmitting(false)
          dispatch({
            type: 'ACCOUNTS',
            payload: undefined,
          })
        })
        .catch(e => {
          setIsSubmitting(false)
        })
    }
  }, [deleteAccount])
  return <>{cloneElement(children, { setDeleteAccount, isSubmitting })}</>
}
