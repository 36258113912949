import axios from 'axios'
import settings from '../constants/settings'

export const dataProvider = ({ baseURL, token, headers }) => {
  const defaultHeaders = {
    Accept: 'application/vnd.api+json; charset=utf-8',
    'Content-Type': 'application/vnd.api+json; charset=utf-8',
    Authorization: 'Bearer ' + token,
  }
  const config = {
    baseURL: baseURL || settings.baseURL,
    headers: headers || defaultHeaders,
  }
  return axios.create(config)
}
