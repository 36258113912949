import React, { useEffect, cloneElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUid } from '../hooks/uid'
import { useDataProvider } from '../hooks/dataProvider'
import { get, find, isUndefined } from 'lodash'

export default props => {
  const { children } = props
  const dataProvider = useDataProvider()
  const data = useSelector(state => state.accounts.accounts)
  const uid = useUid()
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      // You can await here

      const response = await dataProvider.get(
        `jsonapi/node/user_account_connector?filter[field_user.id][value]=${uid}&include=field_account,field_account.field_house_single,
        field_account.field_house_single.field_image,
        field_account.field_house_single.field_area,
        field_account.field_house_single.field_area.field_region,
        field_account.field_house_single.field_firm`
      )
      const data = get(response, 'data.data', {})
      const included = get(response, 'data.included', {})
      const normalizedData = data.map(item => {
        const accountId = get(item, 'relationships.field_account.data.id')
        const field_account = find(included, { id: accountId })
        const houseId = get(
          field_account,
          'relationships.field_house_single.data.id'
        )
        const field_house = find(included, { id: houseId })
        const imageId = get(field_house, 'relationships.field_image.data.id')
        const field_image = find(included, { id: imageId })
        const areaId = get(field_house, 'relationships.field_area.data.id')
        const field_area = find(included, { id: areaId })
        const regionId = get(field_area, 'relationships.field_region.data.id')
        const field_region = find(included, { id: regionId })
        const firmId = get(field_house, 'relationships.field_firm.data.id')
        const field_firm = find(included, { id: firmId })
        item.included = {
          field_account,
          field_house,
          field_image,
          field_area,
          field_region,
          field_firm,
        }
        return item
      })

      dispatch({
        type: 'ACCOUNTS',
        payload: normalizedData,
      })
      dispatch({
        type: 'ACCOUNT_CLEAR_DATA',
      })
    }
    if (isUndefined(data)) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return <>{cloneElement(children, { data })}</>
}
