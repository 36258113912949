import React from 'react'
import { Button } from '@material-ui/core'


export default ({ accountId, setDeleteAccount, isSubmitting }) => {
    return (
        <Button
            onClick={() => {
                setDeleteAccount(accountId)
            }}
            disabled={isSubmitting}
            color='secondary'
            variant='outlined'
            size='large'
        >
            Удалить
        </Button>
    )
}