import React from 'react'
import Preloader from '../preloader'
import {
  Box,
  Fade,
  Select,
  MenuItem,
  Typography,
  TableRow,
  Table,
  TableBody,
  TableCell,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { get, map, isEmpty, isUndefined } from 'lodash'
import { formatMoney, formatDate } from '../../core/tools/tools'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {},
  select: {
    paddingBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  tableRow: {
    backgroundColor: 'inherit',
    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}))

export default function PaymentsBlock({ data = {}, years, year, setYear }) {
  const { payments } = data
  const loadingData = isEmpty(data)
  const classes = useStyles()
  return (
    <>
      {isUndefined(years) ? (
        <Preloader />
      ) : (
        <Fade in={!isUndefined(years)}>
          {isEmpty(years) ? (
            <Box>
              <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                История платежей отсутствует
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box className={classes.header}>
                <Box>
                  <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                    История платежей
                  </Typography>
                </Box>

                <Box className={classes.select}>
                  {!isEmpty(year) && (
                    <Select
                      label='Год'
                      id='year-select'
                      variant='outlined'
                      value={year}
                      onChange={(e) => {
                        setYear(e.target.value)
                      }}
                    >
                      {years.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item} год
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
              </Box>
              {loadingData ? (
                <Preloader />
              ) : (
                <Fade in={!loadingData}>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableBody>
                      {map(payments, (payment, key) => {
                        return (
                          <TableRow className={classes.tableRow} key={key}>
                            <TableCell>
                              {formatDate(
                                get(payment, 'attributes.field_date')
                              )}
                            </TableCell>
                            <TableCell align='right'>
                              {formatMoney(
                                get(payment, 'attributes.field_paid') +
                                get(payment, 'attributes.field_penalties_paid')
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Fade>
              )}
            </Box>
          )}
        </Fade>
      )}
    </>
  )
}

PaymentsBlock.propTypes = {
  data: PropTypes.object,
  years: PropTypes.array,
  year: PropTypes.string,
  setYear: PropTypes.func
}