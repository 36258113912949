import React, { useState } from 'react'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import {
  CardMedia,
  Hidden,
  Card,
  Typography,
  Button,
  Box,
} from '@material-ui/core'
import settings from '../../core/constants/settings'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import DeleteAccount from '../../core/components/delete-account'
import DeleteAccountButton from './delete-account-button'
import LinkToAccount from './link-to-account'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../ui/dialog-title'

const useStyles = makeStyles((theme) => {
  return {
    row: {
      display: 'flex',
      width: '100%',
      alignItems: 'stretch',
      textDecoration: 'none',
      marginBottom: theme.spacing(2),
    },
    denied: {
      borderRight: `solid 4px ${theme.palette.secondary.main}`,
    },
    accepted: {
      borderRight: `solid 4px ${theme.palette.success.main}`,
    },
    waiting: {
      borderRight: `solid 4px ${theme.palette.warning.main}`,
    },
    colImg: {
      minWidth: '100px',
      minHeight: '100px',
      display: 'flex',
      alignItems: 'center',
    },
    colAddress: {
      flexGrow: '1',
      padding: '30px',
      minHeight: '100px',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      },
    },
    colAccount: {
      padding: '0 30px',
      display: 'flex',
      borderLeft: 'solid 1px #e5e5e5',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    colDeleteAccount: {
      padding: '0 20px',
      display: 'flex',
      borderLeft: 'solid 1px #e5e5e5',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    deleteButtons: {
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
    },
    shortAddress: {},
    fullAddress: {},
    media: {
      height: '100px',
      width: '100px',
    },
    linkWide: {
      width: '100%',
    }
  }
})

export default function CardComponent(props) {
  const { item, accountId, statusCode, index, data } = props
  const accountConnectorId = get(item, 'id')
  const classes = useStyles()
  const [visible, setVisible] = useState(false)
  const toggleModal = () => {
    setVisible(!visible)
  }
  const dispatch = useDispatch()
  const setCurrentAccount = (index) => {
    dispatch({
      type: 'SET_CURRENT_ACCOUNT',
      payload: get(data, index),
    })
  }

  return (
    <Card
      className={`${classes.row} ${get(classes, statusCode)}`}
      key={accountId}
    >
      <Hidden mdDown>
        <LinkToAccount
          setCurrentAccount={setCurrentAccount}
          index={index}
          accountId={accountId}
        >
          <div className={classes.colImg}>
            <CardMedia
              className={classes.media}
              image={
                settings.baseURL +
                get(item, 'included.field_image.attributes.uri.url')
              }
            />
          </div>
        </LinkToAccount>
      </Hidden>
      <Hidden smDown>
        <div className={classes.linkWide}>
          <LinkToAccount
            setCurrentAccount={setCurrentAccount}
            index={index}
            accountId={accountId}
          >
            <div className={classes.colAddress}>
              <Typography variant='h6' className={classes.shortAddress}>
                {get(item, 'included.field_house.attributes.title') + ' '}
              </Typography>
              <Typography variant='body2' className={classes.fullAddress}>
                {get(item, 'included.field_region.attributes.title') + ', '}
                {get(item, 'included.field_area.attributes.title') + ', '}
                {get(item, 'included.field_house.attributes.title') + ' '}
                {get(item, 'included.field_account.attributes.field_room_type')}
                &nbsp;
                {get(
                  item,
                  'included.field_account.attributes.field_room_number'
                )}
              </Typography>
            </div>
            <div className={classes.colAccount}>
              <Typography>
                ЛС&nbsp;№
                {get(item, 'included.field_account.attributes.title')}
              </Typography>
            </div>
          </LinkToAccount>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.linkWide}>
          <LinkToAccount
            setCurrentAccount={setCurrentAccount}
            index={index}
            accountId={accountId}
          >
            <div className={classes.colAddress}>
              <Typography variant='h6' className={classes.shortAddress}>
                {get(item, 'included.field_house.attributes.title') + ', '}
                {get(item, 'included.field_account.attributes.field_room_type')}
              &nbsp;
              {get(item, 'included.field_account.attributes.field_room_number')}
                <br />
              ЛС&nbsp;№
              {get(item, 'included.field_account.attributes.title')}
              </Typography>
            </div>
          </LinkToAccount>
        </div>
      </Hidden>
      <div className={classes.colDeleteAccount}>
        <Typography>
          <Button
            onClick={() => {
              toggleModal()
            }}
            color='primary'
            variant='outlined'
            size='large'
          >
            Удалить
          </Button>
          <Dialog
            open={visible}
            onClose={toggleModal}
            aria-labelledby='form-dialog-title'
            fullWidth
          >
            <DialogTitle onClose={toggleModal}>Удаление адреса</DialogTitle>
            <DialogContent>
              <ErrorOutlineIcon fontSize='large' color='primary' />
              <Box mb={2}>
                <Typography>Вы уверены, что хотите удалить адрес?</Typography>
              </Box>
              <Box mb={2} className={classes.deleteButtons}>
                <DeleteAccount>
                  <DeleteAccountButton accountId={accountConnectorId} />
                </DeleteAccount>
                <Button
                  onClick={toggleModal}
                  color='primary'
                  variant='outlined'
                  size='large'
                >
                  Отмена
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Typography>
      </div>
    </Card>
  )
}

CardComponent.propTypes = {
  accountId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  statusCode: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}
