import React from 'react'
import { Typography, Box, Fade, Paper, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'

const faqs = [
  {
    q: 'Могу ли оплатить сумму, отличную от той, что указана в начислении?',
    a:
      'Да, можете. Если вы заплатите больше, то остаток будет учтен за следующий месяц. Если вы заплатите меньше, то у вас появится долг.',
  },
  {
    q:
      'Отображаются ли в Личном кабинете информация об оплате через терминал или другой банк?',
    a: `В личном кабинете отображается информация о всех платежах. Обычно она появляется не позднее 7 дней с момента оплаты. В будущем информация о платежах будет отображаться мгновенно. Если какая-либо оплата не отобразилась в личном кабинете, обратитесь в наш Контакт Центр, нажав кнопку НАПИСАТЬ ОБРАЩЕНИЕ или по телефону +7 800 500 777 9.`,
  },
  {
    q: 'С какой скоростью проходит оплата онлайн?',
    a:
      'Оплата проходит день в день, но информация об этом отразится в Личном кабинете не позднее 7 дней. Датой платежа будет считаться дата вашей фактической оплаты, и если она произведена до крайнего срока, то задолженность не возникнет.',
  },
  {
    q: 'Что делать, если начислена неправильная сумма?',
    a:
      'Все расчеты делаются автоматически, и вероятность неправильных начислений крайне мала. Но если такая ситуация все же возникла, обратитесь в наш Контакт Центр, нажав кнопку НАПИСАТЬ ОБРАЩЕНИЕ или по телефону +7 800 500 777 9. Мы обязательно решим вашу проблему.',
  },
  {
    q:
      'Наступило 1 число месяца, а новых начислений до сих пор нет. Что делать?',
    a:
      'Обычно начисления за прошедший месяц появляются к 5 числу. Связанно это с тем, что мы ждем реестры оплат прошлого месяца от некоторых банков. Это занимает несколько дней. В будущем мы постараемся сделать так, чтобы вся информация в личном кабинете обновлялась более оперативно.',
  },
  {
    q:
      'Почему в личном кабинете нет возможности передать показания приборов учета?',
    a: `Все управляющие компании, входящие в группу Домохозяин, работают на прямых договорах с ресурсоснабжающими организациями. Это - наша принципиальная позиция. Мы - сервисная компания и умеем хорошо обслуживать дома, и не хотим отвечать за деньги плохо работающих «ресурсников».
      Передать показания ИПУ можно или напрямую поставщику ресурса (если он выставляет квитанции самостоятельно), или его агенту (если платежки выставляет Агент). В каждом доме это может быть по-разному. Чтобы уточнить информацию, обратитесь к своему Управляющему или в наш Контакт Центр по телефону +7 800 500 777 9.`,
  },
]

const useStyles = makeStyles((theme) => ({
  table: {},
  panelRoot: {
    boxShadow: 'none',
  },
  heading: {
    fontWeight: 'bold',
  },
  paperRoot: {
    padding: theme.spacing(3),
  },
}))

export default function Faq({ tab, setTab }) {
  if (tab !== 5) {
    setTab(5)
  }

  const classes = useStyles()

  return (
    <Fade in={true}>
      <Box>
        <Paper className={classes.paperRoot} square>
          <Grid container>
            <Grid item xs={12} className={classes.leftCol}>
              {faqs.map((item, index) => {
                return (
                  <ExpansionPanel className={classes.panelRoot} key={index}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      id={`panel-header-${index}`}
                    >
                      <Typography className={classes.heading}>
                        {item.q}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography style={{ whiteSpace: 'pre-line' }}>
                        {item.a}
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                )
              })}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Fade>
  )
}

Faq.propTypes = {
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired
}