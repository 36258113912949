import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch, Link } from 'react-router-dom'
import Preloader from '../preloader'
import { Box, Fade, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'
import { round, formatMoney, requestTimeLimit } from '../../core/tools/tools'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'relative',
      padding: theme.spacing(4),
      width: '100%',
    },
    item: {
      marginBottom: theme.spacing(3),
    },
    header: {
      marginBottom: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    blockHeader: {
      marginBottom: theme.spacing(3),
      fontWeight: 'bold',
    },
    bold: {
      fontWeight: 'bold',
    },
  }
})

export default () => {
  const classes = useStyles()
  let { url } = useRouteMatch()

  const totals = {
    penalties: 0,
  }

  const debt = useSelector((state) => get(state, 'account.toPay.debt', 0))
  const currMonthBill = (useSelector((state) =>
    get(state, 'account.lastBill.bill', []))
  )

  currMonthBill.map((row) => {
    const penalties =
      parseFloat(
        get(row, 'attributes.field_penalties_accrued', 0)
      ) +
      parseFloat(
        get(row, 'attributes.field_penalties_recalculation', 0)
      )
    totals.penalties += penalties
  })

  const toPaySum = useSelector((state) =>
    get(state, 'account.toPay.toPaySum', 0)
  )
  const currMonthPays = useSelector((state) =>
    get(state, 'account.toPay.currMonthPays', 0)
  )
  const penaltiesStart = useSelector((state) =>
    get(state, 'account.lastBill.penaltiesStart', 0)
  )
  const monthName = useSelector((state) =>
    get(state, 'account.lastBill.monthName', 0)
  )
  const monthNameNext = useSelector((state) =>
    get(state, 'account.lastBill.monthNameNext', 0)
  )
  const accrued = useSelector((state) =>
    get(state, 'account.lastBill.accrued', 0)
  )
  const accruedFormated = useSelector((state) =>
    get(state, 'account.lastBill.accruedFormated', 0)
  )
  const loading = !monthName || !accruedFormated

  return (
    <Box className={classes.root}>
      {loading ? (
        <Preloader />
      ) : (
        <Fade in={!loading}>
          <Box>
            <Box>
              <Typography className={classes.blockHeader} variant='h6'>
                К оплате
              </Typography>
            </Box>
            <Box className={classes.header}>
              <Typography variant='subtitle1'>
                Начислено за {monthName}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography variant='h5'>
                {formatMoney(round(accrued + totals.penalties))}
              </Typography>
            </Box>
            <Box className={classes.header}>
              <Typography variant='subtitle1'>
                Оплачено в {monthNameNext}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography variant='h5'>{currMonthPays}</Typography>
            </Box>
            <Box className={classes.header}>
              <Typography variant='subtitle1'>
                {`${debt < 0 ? 'Переплата' : 'Задолженность'} прошлых периодов`}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography variant='h5'>
                {formatMoney(round(Math.abs(debt)))}
              </Typography>
            </Box>
            <Box className={classes.header}>
              <Typography variant='subtitle1'>
                {`${toPaySum < 0 ? 'Переплата' : 'К оплате'}`}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography variant='h5'>
                {formatMoney(Math.abs(toPaySum))}
              </Typography>
            </Box>
            {toPaySum > 0 && (
              <Link to={`${url}/payment`}>
                <Button
                  className={classes.button}
                  fullWidth
                  color='primary'
                  variant='contained'
                  size='large'
                  onClick={() => { }}
                >
                  Оплатить
                </Button>
              </Link>
            )}
          </Box>
        </Fade>
      )}
    </Box>
  )
}
