export default (state = {}, action) => {
  switch (action.type) {
    case 'ACCOUNT_TO_PAY':
      return {
        ...state,
        toPay: action.payload,
      }
    case 'ACCOUNT_ACCRUALS':
      return {
        ...state,
        accruals: action.payload,
      }
    case 'ACCOUNT_ACCRUALS_YEARS':
      return {
        ...state,
        accrualsYears: action.payload,
      }
    case 'ACCOUNT_ACCRUALS_YEAR':
      return {
        ...state,
        accrualsYear: action.payload,
      }

    case 'ACCOUNT_IPU_VALUES_YEARS':
      return {
        ...state,
        ipuValuesYears: action.payload,
      }
    case 'ACCOUNT_IPU_VALUES_YEAR':
      return {
        ...state,
        ipuValuesYear: action.payload,
      }
    case 'ACCOUNT_IPU_VALUES':
      return {
        ...state,
        ipuValues: action.payload,
      }
    case 'ACCOUNT_IPU_LAST_VALUES':
      return {
        ...state,
        ipuLastValues: action.payload,
      }
    case 'ACCOUNT_IPUS':
      return {
        ...state,
        ipus: action.payload,
      }

    case 'ACCOUNT_PAYMENTS':
      return {
        ...state,
        payments: action.payload,
      }
    case 'ACCOUNT_PAYMENTS_YEARS':
      return {
        ...state,
        paymentsYears: action.payload,
      }
    case 'ACCOUNT_PAYMENTS_YEAR':
      return {
        ...state,
        paymentsYear: action.payload,
      }

    case 'ACCOUNT_LAST_BILL':
      return {
        ...state,
        lastBill: action.payload,
      }
    case 'ACCOUNT_CURR_MONTH_PAYS':
      return {
        ...state,
        currMonthPays: action.payload,
      }
    case 'ACCOUNT_CLEAR_DATA':
      return {}
    default:
      return state
  }
}
