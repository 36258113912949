import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { get, isUndefined } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Fade, Typography, Grid, Box, Paper, Button } from '@material-ui/core'
import Preloader from '../preloader'
import Header from '../header'
import Card from './card'
import AddAccountView from './add-account'
import AddAccount from '../../core/components/add-account'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => {
  return {
    link: {
      textDecoration: 'none',
    },
    noAccounts: {
      padding: theme.spacing(3),
    },
  }
})

const Accounts = ({ data }) => {
  const loading = isUndefined(data)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    dispatch({
      type: 'ACCOUNT_CLEAR_DATA',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Header>
        <h2>Мои адреса</h2>
      </Header>
      {loading ? (
        <Preloader />
      ) : (
        <Fade in={!loading}>
          <Box className={classes.accounts}>
            {!data.length ? (
              // Нет ЛС
              <Grid container spacing={3}>
                <Grid item sm={12} md={10} lg={8}>
                  <Paper className={classes.paper} variant='outlined'>
                    <Box className={classes.noAccounts}>
                      <Typography variant='body1'>
                        Добавьте адрес и лицевой счёт, что бы получить полный
                        доступ к личному кабинету
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                {
                  // Есть ЛС
                  data.map((item, index) => {
                    const accountId = get(item, 'included.field_account.id')
                    const statusCode = get(
                      item,
                      'attributes.field_connector_status'
                    )
                    if (statusCode === 'accepted') {
                      return (
                          <Card
                            item={item}
                            accountId={accountId}
                            statusCode={statusCode}
                            key={index}
                            index={index}
                            data={data}
                          ></Card>
                      )
                    } else {
                      return (
                        <Card
                          key={index}
                          item={item}
                          accountId={accountId}
                          statusCode={statusCode}
                        ></Card>
                      )
                    }
                  })
                }
              </Fragment>
            )}

            <Box mt={4}>
              <Button
                onClick={() => {
                  setOpen(true)
                }}
                color='primary'
                variant='outlined'
                size='large'
              >
                Добавить адрес
              </Button>
              <AddAccount open={open}>
                <AddAccountView open={open} setOpen={setOpen} />
              </AddAccount>
            </Box>
          </Box>
        </Fade>
      )}
    </Fragment>
  )
}

Accounts.propTypes = {
  data: PropTypes.array
}

export default Accounts
