import React, { useEffect, useState, cloneElement } from 'react'
import { useDataProvider } from '../hooks/dataProvider'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import settings from '../constants/settings'

export default props => {
  const { children } = props
  const dataProvider = useDataProvider()
  const name = useSelector(state => {
    return get(state, 'auth.user.current_user.fio')
  })
  const phone = useSelector(state => {
    return get(state, 'auth.user.current_user.name')
  })
  const mail = useSelector(state => {
    return get(state, 'auth.user.current_user.email')
  })
  const account = useSelector(state => {
    return get(state, 'main.currentAccount')
  })
  const accountId = get(account, 'included.field_account.attributes.title')
  const cityTitle = get(account, 'included.field_area.attributes.title')
  const roomType = get(
    account,
    'included.field_account.attributes.field_room_type'
  )
  const roomNumber = get(
    account,
    'included.field_account.attributes.field_room_number'
  )
  const houseTitle = get(account, 'included.field_house.attributes.title')

  const uploadFile = (file, fileContent) => {
    const files = get(state, 'files')
    files.push({ name: file.name, fid: 32 })
    setState({
      ...state,
      files,
    })
  }

  const [state, setState] = useState({
    submitToggle: false,
    submitFileToggle: false,
    accountConnector: useSelector(state => {
      return get(state, 'main.currentAccount.id')
    }),
    message: {
      type: '',
      message: '',
    },
    values: {
      subject: '',
      message: '',
    },
    fio: name,
    mail,
    account: accountId,
    success: false,
    files: [],
  })

  const [firstRun, setFirstRun] = useState(true)

  useEffect(() => {
    const createAppeal = () => {
      dataProvider
        .post(`jsonapi/contact_message/appeal`, {
          data: {
            type: 'contact_message--appeal',
            attributes: {
              subject: state.values.subject,
              message: state.values.message,
              name,
              mail,
              field_phone: phone,
              field_address: `${cityTitle}, ${houseTitle} ${roomType} ${roomNumber}`,
            },
            relationships: {
              field_user_account_connector: {
                data: {
                  type: 'node--user_account_connector',
                  id: state.accountConnector,
                },
              },
              uid: {
                data: {
                  type: 'contact_form--contact_form',
                  id: settings.contactAppealFormId,
                },
              },
            },
          },
        })
        .then(response => {
          state.actions.setSubmitting(false)
          setState({
            ...state,
            success: true,
          })
        })
        .catch(e => {
          state.actions.setSubmitting(false)
          setState({ ...state, message: { type: 'error', message: e.message } })
        })
    }

    if (firstRun) {
      setFirstRun(false)
    } else {
      createAppeal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.submitToggle, state.submitFileToggle])

  return <>{cloneElement(children, { state, setState, uploadFile })}</>
}
