import React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

export default function ButtonComponent(props) {
  const { children, ...rest } = props
  return (
    <Button
      type='submit'
      fullWidth
      variant='contained'
      color='primary'
      size='large'
      {...rest}
    >
      {props.children}
    </Button>
  )
}

ButtonComponent.propTypes = {
  children: PropTypes.string.isRequired,
}