import React from 'react'
import Preloader from '../preloader'
import {
  Box,
  Fade,
  Select,
  MenuItem,
  Typography,
  TableRow,
  Table,
  TableBody,
  TableCell,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { get, map, isEmpty, isUndefined } from 'lodash'
import { formatDate } from '../../core/tools/tools'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  panelRoot: {
    boxShadow: 'none',
  },
  heading: {
    fontWeight: 'bold',
  },
  paperRoot: {
    padding: theme.spacing(3),
  },
}))

export default function IpusBlock({ data, years, year, setYear }) {
  const classes = useStyles()
  const loadingData = isUndefined(data)

  return (
    <Box
      style={{
        position: 'relative',
      }}
    >
      {isUndefined(years) ? (
        <Preloader />
      ) : (
        <Fade in={!isUndefined(years)}>
          {isEmpty(years) ? (
            <Box>
              <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                История показаний отсутствует
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box className={classes.header}>
                <Box>
                  <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                    История показаний
                  </Typography>
                </Box>

                <Box className={classes.select}>
                  {!isEmpty(year) && (
                    <Select
                      label='Год'
                      id='year-select'
                      variant='outlined'
                      value={year}
                      onChange={(e) => {
                        setYear(e.target.value)
                      }}
                    >
                      {years.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item} год
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
              </Box>
              {loadingData ? (
                <Preloader />
              ) : (
                <Fade in={!loadingData}>
                  {isEmpty(data) ? (
                    <Box style={{ marginTop: '20px' }}>
                      <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                        Показания отсутствуют
                      </Typography>
                    </Box>
                  ) : (
                    <Table className={classes.table} aria-label='simple table'>
                      <TableBody>
                        {map(data, (value, key) => {
                          return (
                            <TableRow className={classes.tableRow} key={key}>
                              <TableCell>
                                {get(
                                  value,
                                  'included.field_ipu.attributes.field_text_1'
                                )}
                                ,&nbsp;номер:&nbsp;
                                {get(
                                  value,
                                  'included.field_ipu.attributes.field_counter_number'
                                )}
                              </TableCell>
                              <TableCell>
                                {formatDate(
                                  get(value, 'attributes.field_date')
                                )}
                              </TableCell>
                              <TableCell align='right'>
                                {get(value, 'attributes.field_value[0]')}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  )}
                </Fade>
              )}
            </Box>
          )}
        </Fade>
      )}
    </Box>
  )
}

IpusBlock.propTypes = {
  data: PropTypes.array,
  years: PropTypes.array,
  year: PropTypes.string,
  setYear: PropTypes.func
}