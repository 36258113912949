import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from './appbar'
import { Switch, Route, Redirect } from 'react-router-dom'
import AnonLayout from './anon/anon-layout'
import Logout from '../core/components/logout'
import AccountsView from './accounts/accounts'
import Accounts from '../core/components/accounts'
import { useToken } from '../core/hooks/token'
import AccountLayout from './account-layout'
import AddAccount from './accounts/add-account'
import { useDispatch } from 'react-redux'
import { Box, Grid, CssBaseline, Typography } from '@material-ui/core'
// import BitrixWebChat from './bitrix-chat'
import moment from 'moment'

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column'
    },
    grid: {
      flex: 1
    },
    title: {
      flexGrow: 1
    },
    footer: {
      borderTop: 'solid 1px #e5e5e5',
      backgroundColor: theme.palette.grey[100],
      height: '64px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      marginTop: '50px'
    }
  }
})

const Layout = props => {
  const classes = useStyles()
  const token = useToken()
  const dispatch = useDispatch()
  dispatch({ type: 'REGISTER_CLEAR' })

  if (token) {
    return (
      <Box className={classes.root}>
        <AppBar />
        <Grid
          container
          justify='center'
          component='main'
          className={classes.grid}
        >
          <CssBaseline />
          <Grid item xs={11} sm={10} md={8} className={classes.image}>
            <Switch>
              <Route path='/logout'>
                <Logout />
              </Route>
              <Route path='/login'>
                <Redirect to={{ pathname: '/accounts' }} />
              </Route>
              <Route path='/account/:id'>
                <AccountLayout />
              </Route>
              <Route path='/accounts'>
                <Accounts>
                  <AccountsView />
                </Accounts>
              </Route>
              <Route path='/add-account'>
                <AddAccount />
              </Route>
              <Route path='/'>
                <Redirect to={{ pathname: '/accounts' }} />
              </Route>
            </Switch>
          </Grid>
        </Grid>
        <Box className={classes.footer}>
          <Box>
            <Typography variant='body2'>
              ГК Домохозяин &copy; 2019-{moment().format('YYYY')}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  } else {
    return (
      <Switch>
        <Route path={['/signin', '/registration', '/restore']}>
          <AnonLayout />
        </Route>
        <Route path='/'>
          <Redirect to={{ pathname: '/signin' }} />
        </Route>
      </Switch>
    )
  }
}

export default Layout