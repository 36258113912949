import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import months from '../../core/tools/months'
import Table from './table'
import moment from 'moment'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  table: {},
  panelRoot: {
    boxShadow: 'none'
  },
  heading: {
    fontWeight: 'bold'
  }
})

export default function Panel(props) {
  const { date, month } = props
  const classes = useStyles()
  const objDate = moment(date)
  const monthName = get(months, objDate.format('M') - 1)

  return (
    <ExpansionPanel
      className={classes.panelRoot}
      key={date}
    // defaultExpanded={index === 0}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-content-${date}`}
        id={`panel-header-${date}`}
      >
        <Typography className={classes.heading}>
          {monthName} {objDate.format('YYYY')}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Table month={month}></Table>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

Panel.propTypes = {
  date: PropTypes.string.isRequired,
  month: PropTypes.array.isRequired
}