import React from 'react'
import { ReactComponent as Loading } from 'loading-svg/loading-bars.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  loading: {
    fill: 'red',
    position: 'absolute',
    top: '50%',
    width: '50px',
    height: '50px'
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '200px'
  }
}))

export default props => {
  const classes = useStyles()

  return (
    <div className={classes.loadingWrapper}>
      <Loading className={classes.loading} />
    </div>
  )
}
