import React from 'react'
import { Typography } from '@material-ui/core'
import Button from '../../ui/button'
import TextField from '../../ui/textfield'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Header from '../header'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ym from 'react-yandex-metrika'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 0)
  },

  header: {
    marginBottom: theme.spacing(1)
  }
}))

export default function Profile({ state, setEmail, setFio, register }) {
  const classes = useStyles()
  const { loading, phone, password, message } = state

  const schema = Yup.object().shape({
    fio: Yup.string().required('Заполние это поле'),
    email: Yup.string()
      .email('Неверный формат email')
      .required('Заполние это поле')
  })

  return (
    <>
      <Header message={message} />
      <Formik
        initialValues={{ email: '', fio: '', phone, password }}
        validationSchema={schema}
        onSubmit={values => {
          setFio(values.fio)
          setEmail(values.email)
          ym('reachGoal', 'SIGNUP')
          register(values)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            {loading && <LinearProgress />}
            <Typography variant='h5' className={classes.header}>
              Информация о вас
            </Typography>
            <Typography variant='body1'>
              Необходима для подтверждения личности и отправки важных
              уведомлений
            </Typography>

            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              label='ФИО'
              type='fio'
              name='fio'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fio}
            />

            <TextField
              type='email'
              name='email'
              variant='outlined'
              margin='normal'
              required
              label='E-mail'
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />

            <Button type='submit' className={classes.submit} disabled={loading}>
              Сохранить
            </Button>
          </form>
        )}
      </Formik>
    </>
  )
}

Profile.propStyles = {
  state: PropTypes.object.isRequired,
  setEmail: PropTypes.func.isRequired,
  setFio: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired
}