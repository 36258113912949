import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, TextField, Typography, Box, Collapse } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../ui/dialog-title'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isEmpty, get } from 'lodash'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => {
  return {
    link: {
      textDecoration: 'none',
    },
  }
})

export default function AddAccount(props) {
  const classes = useStyles()

  const {
    open,
    setOpen,
    state,
    setState,
    setHouseSelected,
    setRoomSelected,
    setError,
  } = props

  const {
    housesLoading,
    roomsLoading,
    houses,
    rooms,
    error,
    errorMessage,
    roomSelected,
    houseSelected,
    submitted,
  } = state

  const [account, setAccount] = useState('')
  const [housesOpen, setHousesOpen] = useState(false)
  const [roomsOpen, setRoomsOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    if (roomSelected.title !== account) {
      setError(true, 'Лицевой счёт не соответствует помещению')
    } else {
      setState({
        ...state,
        error: false,
        submitted: true,
      })
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Добавление адреса</DialogTitle>
      <DialogContent>
        {submitted && (
          <Box mb={3}>
            <LinearProgress />
          </Box>
        )}
        <Collapse in={error}>
          <Box mb={3}>
            <Alert
              className={classes.alert}
              variant='outlined'
              severity='error'
            >
              {errorMessage}
            </Alert>
          </Box>
        </Collapse>
        <Box mb={3}>
          <Typography>
            Для добавления адреса в свой личный кабинет, выберите дом, помещение
            и укажите соответствующий номер лицевого счёта.
          </Typography>
        </Box>
        <form onSubmit={onSubmit}>
          <Box mb={3}>
            <Autocomplete
              disabled={submitted}
              open={housesOpen}
              onOpen={() => {
                setHousesOpen(true)
              }}
              onClose={(val) => {
                setHousesOpen(false)
              }}
              getOptionLabel={(option) => {
                return option.title
              }}
              onChange={(event, value) => {
                setHouseSelected(value)
              }}
              options={houses}
              loading={housesLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Дом'
                  fullWidth
                  variant='outlined'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {housesLoading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box mb={3}>
            <Autocomplete
              open={roomsOpen}
              disabled={!state.rooms.length || submitted}
              onOpen={() => {
                setRoomsOpen(true)
              }}
              onClose={() => {
                setRoomsOpen(false)
              }}
              getOptionLabel={(option) => {
                return `${get(option, 'field_room_type', '')} ${get(
                  option,
                  'field_room_number',
                  ''
                )}`
              }}
              onChange={(event, value) => {
                setRoomSelected(value)
              }}
              getOptionSelected={(option, value) => {
                return option.title === value.title
              }}
              value={state.roomSelected}
              options={rooms}
              loading={roomsLoading}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label='Помещение'
                    fullWidth
                    variant='outlined'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {roomsLoading ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )
              }}
            />
          </Box>
          <Box mb={3}>
            <TextField
              id='account'
              disabled={isEmpty(state.roomSelected) || submitted}
              label='Лицевой счёт'
              fullWidth
              variant='outlined'
              value={account}
              onChange={(e) => {
                setAccount(e.target.value)
              }}
            />
          </Box>
          <DialogActions>
            <Button
              disabled={
                isEmpty(houseSelected) ||
                isEmpty(roomSelected) ||
                !account ||
                submitted
              }
              variant='contained'
              color='primary'
              size='large'
              type='submit'
            >
              Добавить
            </Button>
            <Button
              disabled={submitted}
              onClick={handleClose}
              variant='outlined'
              color='primary'
              size='large'
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

AddAccount.propTypes = {
  open: PropTypes.bool,
  setError: PropTypes.func,
  setHouseSelected: PropTypes.func,
  setOpen: PropTypes.func,
  setRoomSelected: PropTypes.func,
  setState: PropTypes.func,
  setSubmitted: PropTypes.func,
  state: PropTypes.object
}