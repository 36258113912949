import React, { useState, Fragment } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Typography } from '@material-ui/core'
import Button from '../../ui/button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import InputMask from 'react-input-mask'
import Header from '../header'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 0)
  },

  header: {
    marginBottom: theme.spacing(1)
  }
}))

export default function Phone({ state, setPhone, checkRoute }) {
  const classes = useStyles()

  const { phone, loading, message } = state
  const [phoneValid, setPhoneValid] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    const shortPhone = phone.replace(/\D+/g, '')
    checkRoute(shortPhone)
  }

  return (
    <Fragment>
      <Header message={message} />
      <form onSubmit={onSubmit} className={classes.form} noValidate>
        {loading && <LinearProgress />}
        <Fragment>
          <Typography variant='h5' className={classes.header}>
            Личный кабинет
          </Typography>
          <Typography variant='body1'>
            Введите ваш номер, чтобы войти или зарегистрироваться.
          </Typography>
          <InputMask
            mask='+7 (999) 999 - 99 - 99'
            value={phone}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='phone'
            label='Номер телефона'
            name='phone'
            autoComplete='tel'
            autoFocus
            disabled={loading}
            onChange={e => {
              setPhone(e.target.value)
              const length = e.target.value.replace(/\D+/g, '').length
              setPhoneValid(length === 11)
            }}
          >
            {inputProps => <TextField {...inputProps} />}
          </InputMask>
          <Button className={classes.submit} disabled={!phoneValid || loading}>
            Войти
          </Button>
          <Button
            variant='outlined'
            className={classes.submit}
            disabled={!phoneValid || loading}
          >
            Зарегистрироваться
          </Button>
        </Fragment>
      </form>
    </Fragment>
  )
}

Phone.propTypes = {
  state: PropTypes.object.isRequired,
  setPhone: PropTypes.func.isRequired,
  checkRoute: PropTypes.func.isRequired
}