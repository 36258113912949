import React from 'react'
import {
  Chart,
  LineSeries,
  ScatterSeries,
  BarSeries,
  ArgumentAxis,
  ValueAxis
} from '@devexpress/dx-react-chart-material-ui'
import { ValueScale } from '@devexpress/dx-react-chart'
import { scaleLinear } from 'd3-scale'
import { useTheme } from '@material-ui/core/styles'
import { symbol, symbolCircle, stack } from 'd3-shape'
import { map, max, min } from 'lodash'
import { scaleBand } from '@devexpress/dx-chart-core'
import { ArgumentScale, Stack } from '@devexpress/dx-react-chart'
import { monthsShort } from '../core/tools/months'
import moment from 'moment'
import PropTypes from 'prop-types'

const Point = (type, styles) => props => {
  const { arg, val, color } = props
  return (
    <path
      fill={color}
      transform={`translate(${arg} ${val})`}
      d={symbol()
        .size([10 ** 2])
        .type(type)()}
      style={styles}
    />
  )
}

const StarPoint = Point(symbolCircle, {
  stroke: 'white',
  strokeWidth: '1px'
})

const LineWithStarPoint = props => {
  const { coordinates } = props
  return (
    <>
      <LineSeries.Path {...props} coordinates={coordinates} />
      <ScatterSeries.Path {...props} pointComponent={StarPoint} />
    </>
  )
}

const scale = () => scaleLinear()
const modifyDomain = domain => {
  const m1 = Math.abs(min(domain)) / 10
  const m2 = Math.abs(max(domain)) / 10
  const margin = m1 > m2 ? m1 : m2
  return [min(domain) - margin, max(domain) + margin]
}

export default function ChartComponent({ data }) {
  const theme = useTheme()
  const chartData = map(data, (item, key) => {
    const monthIndex = parseInt(moment(key).format('M')) - 1
    const argument = monthsShort[monthIndex]

    if (item === null)
      return { argument, empty: '' }
    else
      return { argument, value: item }
  })

  return (
    <Chart data={chartData}>
      <ArgumentScale factory={scaleBand} />
      <ArgumentAxis />
      <ValueAxis />
      <ValueScale factory={scale} modifyDomain={modifyDomain} />
      {/* <Animation /> */}
      <LineSeries
        color={theme.palette.primary.main}
        valueField='value'
        argumentField='argument'
        seriesComponent={LineWithStarPoint}
      />
      <BarSeries
        valueField='empty'
        argumentField='argument'
      />
      <Stack/>
    </Chart>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.object.isRequired
}