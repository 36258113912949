import React, { useState, Fragment, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import Button from '../../ui/button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../header'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 0)
  },

  header: {
    marginBottom: theme.spacing(1)
  }
}))

export default function Password({ state, setPassword, nextStep }) {
  const classes = useStyles()

  const { password, message } = state
  const [repeatPassword, setRepeatPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    nextStep()
  }
  useEffect(() => {
    setPasswordValid(password.length >= 6 && password === repeatPassword)
  }, [password, repeatPassword])

  return (
    <Fragment>
      <Header message={message} />
      <form onSubmit={onSubmit} className={classes.form} noValidate>
        <Fragment>
          <Typography variant='h5' className={classes.header}>
            Создайте пароль
          </Typography>
          <Typography variant='body1'>
            Ваш пароль для входа должен содержать не менее 6 символов
          </Typography>
          <TextField
            value={password}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='password'
            label='Пароль'
            name='password'
            autoComplete='password'
            autoFocus
            type='password'
            onChange={e => {
              setPassword(e.target.value)
            }}
          />
          <TextField
            value={repeatPassword}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='repeat-password'
            label='Повторите пароль'
            name='repeat-password'
            autoComplete='password'
            type='password'
            onChange={e => {
              setRepeatPassword(e.target.value)
            }}
          />
          <Button className={classes.submit} disabled={!passwordValid}>
            Подтвердить
          </Button>
        </Fragment>
      </form>
    </Fragment>
  )
}

Password.propTypes = {
  state: PropTypes.object.isRequired,
  setPassword: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
}