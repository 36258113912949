import React from 'react'
import { Box, Fade, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Ipus from '../../core/components/ipus'
import IpusValues from '../../core/components/ipus-values'
import IpusForm from './ipus-form'
import IpusBlock from './ipus-block'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  table: {},
  panelRoot: {
    boxShadow: 'none',
  },
  heading: {
    fontWeight: 'bold',
  },
  paperRoot: {
    padding: theme.spacing(3),
  },
}))

export default function IpusPage({ tab, setTab }) {
  if (tab !== 4) {
    setTab(4)
  }

  const classes = useStyles()

  return (
    <Fade in={true}>
      <Paper className={classes.paperRoot} square>
        <Ipus>
          <IpusForm />
        </Ipus>
        <IpusValues>
          <IpusBlock />
        </IpusValues>
      </Paper>
    </Fade>
  )
}

IpusPage.propTypes = {
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired
}