import React, { useEffect, cloneElement, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDataProvider } from '../hooks/dataProvider'
import { useParams } from 'react-router-dom'
import { get, set, merge, cloneDeep, each } from 'lodash'
import moment from 'moment'

export default props => {
  const { children } = props
  const dataProvider = useDataProvider()
  const { id } = useParams()
  const account = useSelector(state => state.main.currentAccount)
  const accountTitle = get(account, 'included.field_account.attributes.title')
  const ipus = useSelector(state => get(state, 'account.ipus'))
  const ipuLastValues = useSelector(state =>
    get(state, 'account.ipuLastValues', {})
  )
  const dispatch = useDispatch()

  const setIpuLastValue = value => {
    const newIpuLastValues = cloneDeep(merge(ipuLastValues, value))
    dispatch({
      type: 'ACCOUNT_IPU_LAST_VALUES',
      payload: newIpuLastValues,
    })
  }
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    const pushValue = async (value, ipuId) => {
      const valueMonth = moment(value.date).format('YYYY-MM')
      const currentMonth = moment().format('YYYY-MM')
      const titleDate = moment().format('DD.MM.YYYY')
      const fieldDate = moment().format('YYYY-MM-DD')
      const ipuType = get(value, 'ipu.attributes.field_text_1')
      const ipuNumber = get(value, 'ipu.attributes.field_id')
      const data = {
        type: 'node--ipu_value',
        attributes: {
          title: `${accountTitle}-${ipuType}-${ipuNumber}-${titleDate}`,
          field_value: [value.newValue],
          field_date: fieldDate,
        },
        relationships: {
          field_ipu: {
            data: {
              type: 'node--ipu',
              id: ipuId,
            },
          },
        },
      }
      let method = 'POST'
      let url = 'jsonapi/node/ipu_value'
      if (value.id && valueMonth === currentMonth) {
        data.id = value.id
        url += `/${value.id}`
        method = 'PATCH'
      }
      const response = await dataProvider({
        method: method,
        url,
        data: {
          data,
        },
      })
      const returnedValue = get(response, 'data.data', {})
      const fieldValue = get(returnedValue, 'attributes.field_value[0]', 0)
      const date = get(returnedValue, 'attributes.field_date', '2000-01-01')
      const id = get(returnedValue, 'id')

      setIpuLastValue(
        set({}, ipuId, {
          value: fieldValue,
          newValue: fieldValue,
          date,
          id,
        })
      )
      setSubmitting(false)
      dispatch({
        type: 'ACCOUNT_IPU_VALUES',
        payload: undefined,
      })
    }
    if (isSubmitting) {
      let index = 0
      each(ipuLastValues, (value, ipuId) => {
        setTimeout(() => {
          pushValue(value, ipuId)
        }, 100 * index)
        index++
      })
    }
  }, [isSubmitting])

  useEffect(() => {
    async function fetchIpus() {
      const response = await dataProvider.get(
        `jsonapi/node/ipu?filter[field_account.id]=${id}&filter[field_text_1][value]=ГВС&include=field_account`
      )
      dispatch({
        type: 'ACCOUNT_IPUS',
        payload: get(response, 'data.data', []),
      })
    }
    fetchIpus()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchLastValues(ipu) {
      const response = await dataProvider.get(
        `jsonapi/node/ipu_value?filter[field_ipu.id]=${ipu.id}&page[limit]=1&sort=-field_date`
      )
      const lastValue = get(response, 'data.data[0]', {})
      const value = get(lastValue, 'attributes.field_value[0]', 0)
      const date = get(lastValue, 'attributes.field_date', '2000-01-01')
      const id = get(lastValue, 'id')
      setIpuLastValue(
        set({}, ipu.id, {
          value,
          newValue: value,
          date,
          id,
          ipu,
          error: false,
          helperText: '',
        })
      )
    }

    if (ipus) {
      ipus.map(ipu => {
        return fetchLastValues(ipu)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipus])

  return (
    <>
      {cloneElement(children, {
        ipuLastValues,
        setIpuLastValue,
        isSubmitting,
        setSubmitting,
      })}
    </>
  )
}
