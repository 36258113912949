const initialState = {
  route: '',
  step: 1,
  phone: '',
  code: '',
  password: '',
  fio: '',
  email: '',
  loading: false,
  message: {
    text: '',
    status: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REGISTER_CLEAR':
      return initialState
    case 'REGISTER_LOADING':
      return {
        ...state,
        loading: action.payload
      }
    case 'USER_EXIST':
      return {
        ...state,
        userExist: action.payload
      }
    case 'REGISTER_MESSAGE':
      return {
        ...state,
        message: action.payload
      }
    case 'REGISTER_STEP':
      return {
        ...state,
        step: action.payload
      }
    case 'REGISTER_NEXT_STEP':
      return {
        ...state,
        step: state.step + 1
      }
    case 'REGISTER_ROUTE':
      return {
        ...state,
        route: action.payload
      }
    case 'REGISTER_PHONE':
      return {
        ...state,
        phone: action.payload
      }
    case 'REGISTER_CODE':
      return {
        ...state,
        code: action.payload
      }
    case 'REGISTER_PASSWORD':
      return {
        ...state,
        password: action.payload
      }
    case 'REGISTER_FIO':
      return {
        ...state,
        fio: action.payload
      }
    case 'REGISTER_EMAIL':
      return {
        ...state,
        email: action.payload
      }
    default:
      return state
  }
}
