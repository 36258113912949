import React, { useEffect, useState, cloneElement } from 'react'
import { useDataProvider } from '../hooks/dataProvider'
import { useParams } from 'react-router-dom'
import { get, find, isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

export default (props) => {
  const { children } = props
  const dataProvider = useDataProvider()
  const { id } = useParams()
  const dispatch = useDispatch()
  const year = useSelector((state) => get(state, 'account.ipuValuesYear', ''))
  const years = useSelector((state) => get(state, 'account.ipuValuesYears', []))
  const data = useSelector((state) => state.account.ipuValues)

  const setYear = (year) => {
    dispatch({
      type: 'ACCOUNT_IPU_VALUES_YEAR',
      payload: year,
    })
    dispatch({
      type: 'ACCOUNT_IPU_VALUES',
      payload: undefined,
    })
  }

  const setData = (data) => {
    dispatch({
      type: 'ACCOUNT_IPU_VALUES',
      payload: data,
    })
  }

  useEffect(() => {
    async function fetchFirstValue() {
      const response = await dataProvider.get(
        `jsonapi/node/ipu_value?filter[field_ipu.field_account.id][value]=${id}&filter[field_ipu.field_text_1][value]=ГВС&page[limit]=1&sort=field_date`
      )
      const firstValueDate = get(
        response,
        'data.data[0].attributes.field_date',
        moment()
      )
      const startYear = moment(firstValueDate).format('YYYY')
      const endYear = moment().format('YYYY')
      const years = []
      for (var i = parseInt(endYear); i >= parseInt(startYear); i--) {
        years.push(i.toString())
      }
      dispatch({
        type: 'ACCOUNT_IPU_VALUES_YEARS',
        payload: years,
      })
      if (years.length) {
        setYear(years[0])
      }
    }

    if (isEmpty(years)) {
      fetchFirstValue()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await dataProvider.get(
        `jsonapi/node/ipu_value` +
          `?filter[field_ipu.field_account.id][value]=${id}` +
          `&filter[field_ipu.field_text_1][value]=ГВС` +
          `&filter[date][condition][path]=field_date` +
          `&filter[date][condition][operator]=BETWEEN` +
          `&filter[date][condition][value][]=${year}-01-01` +
          `&filter[date][condition][value][]=${year}-12-31` +
          `&include=field_ipu` +
          `&sort=-field_date`
      )

      const data = get(response, 'data.data', [])
      const included = get(response, 'data.included', {})
      const normalized = data.map((item) => {
        const ipuId = get(item, 'relationships.field_ipu.data.id')
        const field_ipu = find(included, { id: ipuId })
        item.included = {
          field_ipu,
        }
        return item
      })
      setData(normalized)
    }
    if (year && !data) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, data])

  return <>{cloneElement(children, { data, years, year, setYear })}</>
}
