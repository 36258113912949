import React, { Fragment } from 'react'
import { Typography, Box, LinearProgress, TextField } from '@material-ui/core'
import Button from '../../ui/button'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../header'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 0)
  },
  restoreLink: {
    textAlign: 'center',
    marginTop: '10px'
  },
  header: {
    marginBottom: theme.spacing(1)
  }
}))

export default function Login({ state, setPassword, clearState, userLogin, setRoute }) {
  const classes = useStyles()

  const { message, password, phone, loading } = state

  const onSubmit = event => {
    event.preventDefault()
    const shortPhone = phone.replace(/\D+/g, '')
    userLogin(shortPhone, password)
  }

  return (
    <Fragment>
      <Header message={message} />
      <form onSubmit={onSubmit} className={classes.form} noValidate>
        {loading && <LinearProgress />}
        <Fragment>
          <Typography variant='h5' className={classes.header}>
            Введите пароль
          </Typography>
          <Typography variant='body1'>
            Ваш пароль для аккаунта с номером телефона: <br />
            {phone}{' '}
            <a
              href='#change'
              onClick={e => {
                e.preventDefault()
                clearState()
              }}
            >
              Изменить номер
            </a>
          </Typography>
          <TextField
            value={password}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='password'
            label='Пароль'
            name='password'
            autoComplete='password'
            autoFocus
            type='password'
            onChange={e => {
              setPassword(e.target.value)
            }}
          />
          <Button className={classes.submit} disabled={password.length < 6}>
            Войти
          </Button>
          <Box className={classes.restoreLink}>
            <a
              href='#change'
              onClick={e => {
                e.preventDefault()
                setRoute('restore')
              }}
            >
              Забыли пароль?
            </a>
          </Box>
        </Fragment>
      </form>
    </Fragment>
  )
}

Login.propTypes = {
  state: PropTypes.object.isRequired,
  setPassword: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired,
  setRoute: PropTypes.func.isRequired
}