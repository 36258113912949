import React from 'react'
import Preloader from '../preloader'
import {
  Box,
  Fade,
  Select,
  MenuItem,
  Typography,
  Grid,
  Paper,
} from '@material-ui/core'
import map from 'lodash/map'
import { isEmpty, isUndefined, assignWith } from 'lodash'
import Panel from './panel'
import { makeStyles } from '@material-ui/core/styles'
import Chart from '../chart'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  select: {
    paddingBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  paperRoot: {
    padding: theme.spacing(3),
  },
  col1: {
    padding: theme.spacing(3),
  },
  col2: {
    padding: theme.spacing(3),
  },
}))

export default function BillsPage({ data = {}, years, year, setYear, tab, setTab, lastMonthDate }) {
  if (tab !== 1) {
    setTab(1)
  }
  const { totals, months } = data
  const lastDate = new Date(lastMonthDate)

  const modifiedTotals = {}
  assignWith(modifiedTotals, totals, (_, srcValue, key) => {
    const date = new Date(key)
    return date >= lastDate && srcValue == 0 ? null : srcValue
  })

  const classes = useStyles()
  const loadingData = isEmpty(data)
  let panelIndex = 0
  return (
    <>
      {isUndefined(years) ? (
        <Preloader />
      ) : (
        <Fade in={!isUndefined(years)}>
          <Paper>
            {isEmpty(years) ? (
              <Grid container>
                <Grid item xs={12} md={6} lg={7} className={classes.col1}>
                  <Box className={classes.header}>
                    <Box>
                      <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                        История начислений отсутствует
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12} md={6} lg={7} className={classes.col1}>
                  {loadingData ? (
                    <Preloader />
                  ) : (
                    <Fade in={!loadingData}>
                      <Chart data={modifiedTotals} />
                    </Fade>
                  )}
                </Grid>
                <Grid item xs={12} md={6} lg={5} className={classes.col2}>
                  <Box>
                    <Box className={classes.header}>
                      <Box>
                        <Typography style={{ fontWeight: 'bold' }} variant='h6'>
                          История начислений
                        </Typography>
                      </Box>
                      <Box className={classes.select}>
                        <Select
                          label='Год'
                          id='year-select'
                          variant='outlined'
                          value={year}
                          onChange={(e) => {
                            setYear(e.target.value)
                          }}
                        >
                          {years.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item} год
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                    {loadingData ? (
                      <Preloader />
                    ) : (
                      <Fade in={!loadingData}>
                        <Box>
                          {map(months, (month, key) => {
                            return (
                              <Panel
                                key={key}
                                date={key}
                                month={month}
                                index={panelIndex++}
                                loading={loadingData}
                              ></Panel>
                            )
                          })}
                        </Box>
                      </Fade>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Fade>
      )}
    </>
  )
}

BillsPage.propTypes = {
  data: PropTypes.object,
  years: PropTypes.array,
  year: PropTypes.string,
  setYear: PropTypes.func,
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  lastMonthDate: PropTypes.string
}