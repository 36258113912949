import React from 'react'

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      strokeLinejoin='round'
      strokeWidth='28.222'
      preserveAspectRatio='xMidYMid'
      version='1.2'
      viewBox='0 0 15410 2500'
      style={{ width: '100%' }}
    >
      <defs className='ClipPathGroup'>
        <clipPath id='a' clipPathUnits='userSpaceOnUse'>
          <path d='M0 0H15410V2500H0z'></path>
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse'>
          <path d='M15 2H15395V2497H15z'></path>
        </clipPath>
      </defs>
      <defs className='EmbeddedBulletChars'>
        <path
          d='M580 1141l583-570L580 0-4 571l584 570z'
          transform='scale(.00049 -.00049)'
        ></path>
        <path
          d='M8 1128h1129V0H8v1128z'
          transform='scale(.00049 -.00049)'
        ></path>
        <path
          d='M174 0l428 739-428 742 1282-742L174 0zm1184 739L309 1346l350-607h699z'
          transform='scale(.00049 -.00049)'
        ></path>
        <path
          d='M2015 739L1276 0H717l543 543H174v393h1086l-543 545h557l741-742z'
          transform='scale(.00049 -.00049)'
        ></path>
        <path
          d='M0-2c-7 16-16 29-25 39l381 530c-94 256-141 385-141 387 0 25 13 38 40 38 9 0 21-2 34-5 21 4 42 12 65 25l27-13 111-251 280 301 64-25 24 25c21-10 41-24 62-43-36-69-87-143-152-222-1-1-60-68-176-200l180-361c0-27-21-55-63-84l16-20c-10-29-28-43-55-43-31 0-102 102-215 305L164-76c-22-34-53-51-92-51-42 0-63 17-64 51C1-67-2-52-2-32c0 9 1 19 2 30z'
          transform='scale(.00049 -.00049)'
        ></path>
        <path
          d='M285-33C182-33 111 30 74 156c-22 72-33 177-33 315 0 78 14 145 41 201 34 71 87 106 158 106 53 0 88-31 106-94l23-176c8-64 28-97 59-98l735 706c11 11 33 17 66 17 42 0 63-15 63-46V965c0-36-10-64-30-84L442 47C390-6 338-33 285-33z'
          transform='scale(.00049 -.00049)'
        ></path>
        <path
          d='M813 0C632 0 489 54 383 161 276 268 223 411 223 592s53 324 160 431c106 107 249 161 430 161 179 0 323-54 432-161 108-107 162-251 162-431s-54-324-162-431C1136 54 992 0 813 0z'
          transform='scale(.00049 -.00049)'
        ></path>
        <path
          d='M346 457c-73 0-137 26-191 78-54 51-81 114-81 188 0 73 27 136 81 188s118 78 191 78 134-26 185-79c51-51 77-114 77-187 0-75-25-137-76-188-50-52-112-78-186-78z'
          transform='scale(.00049 -.00049)'
        ></path>
        <path
          d='M-4 459h1139v147H-4V459z'
          transform='scale(.00049 -.00049)'
        ></path>
        <path
          d='M173 740c0 163 58 303 173 419 116 115 255 173 419 173 163 0 302-58 418-173 116-116 174-256 174-419s-58-303-174-418c-116-116-255-174-418-174-164 0-303 58-419 174-115 115-173 255-173 418z'
          transform='scale(.00049 -.00049)'
        ></path>
      </defs>
      <g className='SlideGroup'>
        <g className='Slide'>
          <g className='Page'>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M9904 2003H10231V2374H9904z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M9962 2223c0 34 8 59 24 76 16 18 36 27 60 27 35 0 65-20 90-57 24-36 36-76 36-119 0-28-6-52-20-70-15-21-35-31-62-31-40 0-72 19-96 59-21 34-32 72-32 115zm-58 2c0-41 8-79 24-114 33-72 87-108 164-108 43 0 77 13 101 40 25 27 37 63 37 108 0 59-16 110-48 152-35 47-80 70-137 70-37 0-70-12-98-36-29-24-43-61-43-112z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M10260 2003H10778V2365H10260z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M10260 2364l72-352 58-1-12 58c37-44 74-66 113-66 46 0 74 23 83 66 34-43 73-66 116-66 59 0 87 27 87 80 0 13-2 31-7 54l-47 227h-58l47-233c5-21 7-34 7-41 0-25-15-37-42-37-20 0-40 6-59 19-18 13-32 29-41 48-9 20-16 44-22 73l-36 171h-58l49-238c3-15 5-26 5-35 0-25-13-38-38-38-20 0-40 7-60 21-19 14-33 30-42 49-9 21-17 46-23 74l-34 167h-58z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M10778 2010H11170V2370H10778z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M10910 2010h259l-73 354h-58l64-304h-145l-44 212c-7 30-15 53-24 67-14 20-34 30-60 30-12 0-29-2-51-5l10-50c10 1 18 1 26 1 14 0 24-4 29-12s11-31 19-71l48-222z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M11204 2010H11534V2374H11204z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M11419 2300c-41 48-83 73-125 73-24 0-45-7-62-21-18-15-28-35-28-59 0-15 4-41 12-78l41-205h58l-46 227c-4 20-6 35-6 44 0 14 4 24 12 32 8 7 20 10 35 10 22 0 43-7 64-22 20-14 35-32 44-53 9-20 17-48 25-84l32-154h58l-71 354h-56l13-64z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M11581 2010H11880V2365H11581z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M11606 2010h58l-13 59c-8 41-12 67-12 80 0 31 24 47 70 47 28 0 54-5 77-15l35-171h58l-73 354h-58l28-135c-29 10-56 15-81 15-32 0-59-6-79-19-23-15-35-38-35-69 0-15 5-47 15-99l10-47z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.PolyPolygonShape'>
              <path
                fill='none'
                d='M11896 2010H12244V2365H11896z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M11970 2010h58l-31 149h159l31-149h56l-72 354h-58l33-158h-159l-33 158h-58l74-354z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M12260 2010H12733V2365H12260z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M12673 2010h59l-75 354h-58l74-354zm-346 307h76c29 0 49-2 61-7 13-4 23-11 31-21s12-23 12-38c0-14-4-25-11-34-8-10-16-16-24-18s-26-3-53-3h-67l-25 121zm6-307h60l-31 138h64c33 0 56 1 69 4 26 6 44 18 56 37 10 16 15 34 15 53 0 23-7 45-19 66-14 23-30 38-50 45-19 8-50 11-91 11h-146l73-354z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M12766 1873H13097V2374H12766z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M12870 1874h38c3 29 25 43 65 43 39 0 65-14 78-43h42c-21 56-62 85-123 85-29 0-52-7-70-20-20-15-30-37-30-65zm-48 136h57l-45 215c-6 28-8 46-8 55 0 27 15 41 46 41 23 0 44-7 64-22 21-15 35-31 44-50 8-17 16-44 24-81l34-158h58l-74 354h-51l13-66c-39 50-83 75-130 75-25 0-46-8-63-22-16-15-25-35-25-62 0-15 4-37 10-67l46-212z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M13315 2003H13617V2374H13315z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M13536 2235l58 6c-29 88-80 132-154 132-40 0-72-14-94-41-21-26-31-60-31-101 0-63 15-117 47-162 31-44 75-66 133-66 36 0 65 10 87 30 23 21 34 50 34 87l-59 3c0-24-6-42-19-54-13-11-30-17-49-17-38 0-67 19-87 57-19 38-29 81-29 128 0 24 6 44 16 60 12 20 29 29 52 29 43 0 75-31 95-91z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M13643 2003H13964V2374H13643z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M13714 2156h194v-13c0-32-8-56-23-71-16-15-34-23-57-23-29 0-53 11-74 33-19 19-32 44-40 74zm170 88l57 6c-9 34-30 63-61 87-33 24-66 36-101 36-43 0-76-15-100-43-23-29-35-64-35-108 0-58 16-109 49-153s77-66 133-66c44 0 79 13 103 41 22 26 34 62 34 108 0 16-2 32-5 50h-254c-1 6-1 12-1 18 0 34 7 59 23 76 15 18 33 27 52 27 23 0 45-7 64-23 18-15 32-33 42-56z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M13970 2003H14334V2501H13970z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M14085 2231c0 30 8 54 23 69 15 16 33 23 54 23 34 0 63-22 84-67 18-36 27-74 27-113 0-24-6-45-18-64-12-18-30-28-54-28-36 0-65 22-87 66-19 36-29 74-29 114zm-114 269l99-489 53-1-9 50c34-38 69-57 104-57 33 0 61 12 83 36 21 24 32 60 32 106 0 54-15 104-45 150-33 52-74 78-124 78-42 0-73-22-96-65l-39 192h-58z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M14375 2003H14683V2374H14375z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M14447 2153c16 2 30 2 41 2 88 0 133-20 133-60 0-14-6-25-16-34-10-7-22-11-36-11-29 0-55 10-78 30-21 19-35 43-44 73zm-9 48c-1 9-2 18-2 27 0 30 8 53 24 70 16 19 38 27 66 27 20 0 37-5 51-17 15-12 23-28 23-47 0-29-16-48-48-56-14-4-41-5-83-5-5 0-15 0-31 1zm157-22c22 8 39 18 49 31 11 13 16 30 16 51 0 26-12 51-35 76-24 24-58 36-103 36-61 0-104-23-129-70-12-22-18-46-18-72 0-64 18-118 54-162 35-44 82-66 142-66 30 0 55 6 75 20 23 15 35 38 35 67 0 45-29 74-86 89z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M14731 2010H15061V2374H14731z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M14946 2300c-41 48-83 73-125 73-24 0-45-7-62-21-19-15-28-35-28-59 0-15 4-41 11-78l41-205h59l-46 227c-4 20-6 35-6 44 0 14 4 24 12 32 8 7 20 10 35 10 22 0 43-7 64-22 20-14 35-32 44-53 9-20 17-48 24-84l32-154h59l-73 354h-54l13-64z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M15095 2003H15398V2374H15095z'
                className='BoundingBox'
              ></path>
              <path
                fill='#8A8A8C'
                d='M15318 2235l58 6c-29 88-80 132-155 132-40 0-71-14-93-41-21-26-33-60-33-101 0-63 17-117 48-162 32-44 76-66 134-66 36 0 65 10 87 30 22 21 33 50 33 87l-58 3c0-24-6-42-19-54-13-11-30-17-49-17-38 0-67 19-87 57-19 38-29 81-29 128 0 24 5 44 16 60 12 20 29 29 52 29 43 0 75-31 95-91z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M3261 24H4842V1692H3261z'
                className='BoundingBox'
              ></path>
              <path
                fill='#005694'
                d='M3577 1487l483-1200 464 1200h-947zm1241 0h-63L4176 39c-4-8-12-14-21-14h-190c-9 0-17 5-21 14l-599 1448h-62c-12 0-22 10-22 22v159c0 12 10 23 22 23h1535c13 0 23-11 23-23v-159c0-12-10-22-23-22z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M4866 435H6130V1726H4866z'
                className='BoundingBox'
              ></path>
              <path
                fill='#005694'
                d='M5797 1389c-82 87-182 131-300 131-124 0-226-43-305-128-78-86-118-192-118-318 0-122 41-226 122-309 81-84 183-126 301-126 119 0 220 42 302 125 80 83 121 188 121 310 0 123-41 229-123 315zm-300-954c-172 0-322 62-445 186-123 123-186 276-186 453 0 180 62 335 184 461 122 125 272 190 447 190 174 0 325-65 447-191s184-281 184-460c0-178-62-331-184-454s-273-185-447-185z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M6247 469H7448V1692H6247z'
                className='BoundingBox'
              ></path>
              <path
                fill='#005694'
                d='M7425 469h-171c-9 0-17 5-21 14l-386 931-386-931c-4-9-12-14-21-14h-170c-12 0-22 10-22 23v1176c0 12 10 23 22 23h153c12 0 22-11 22-23V953l297 724c4 8 12 14 21 14h170c9 0 17-6 21-14l296-724v715c0 12 10 23 22 23h153c12 0 22-11 22-23V492c0-13-10-23-22-23z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M7543 435H8805V1726H7543z'
                className='BoundingBox'
              ></path>
              <path
                fill='#005694'
                d='M8474 1389c-81 87-182 131-300 131-124 0-227-43-305-128-79-86-119-192-119-318 0-122 41-226 123-309 81-84 182-126 301-126 120 0 221 42 301 125 81 83 122 188 122 310 0 123-41 229-123 315zm146-769c-121-123-271-185-446-185-173 0-323 62-446 186-123 123-185 276-185 453 0 180 62 335 184 461 121 125 272 190 447 190s325-65 447-191c121-126 183-281 183-460 0-178-62-331-184-454z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M8842 469H9882V1692H8842z'
                className='BoundingBox'
              ></path>
              <path
                fill='#DB1921'
                d='M9485 1045l370-540c5-7 6-16 2-23-4-8-11-13-20-13h-193c-8 0-15 4-19 10l-264 407-263-407c-4-6-11-10-19-10h-195c-8 0-16 5-20 12-4 8-3 17 2 24l374 540-394 610c-5 7-5 16-1 23 4 8 11 13 20 13h195c7 0 14-4 19-11l282-442 284 442c4 7 11 11 19 11h194c8 0 16-5 20-13 4-7 3-16-1-23l-392-610z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M9892 435H11154V1726H9892z'
                className='BoundingBox'
              ></path>
              <path
                fill='#DB1921'
                d='M10946 1074c0 123-41 229-123 315-82 87-183 131-300 131-124 0-227-43-305-128-79-86-119-192-119-318 0-122 42-226 123-309 81-84 182-126 301-126s220 42 302 125c80 83 121 188 121 310zm-423-639c-173 0-323 62-446 186-123 123-185 276-185 453 0 180 62 335 184 460 122 126 272 191 447 191 174 0 325-65 447-191s183-281 183-460c0-178-62-331-184-454-121-123-272-185-446-185z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M11240 469H11982V1692H11240z'
                className='BoundingBox'
              ></path>
              <path
                fill='#DB1921'
                d='M11800 1032c29-22 54-53 74-92 27-53 41-110 41-169 0-92-38-168-114-225-67-51-146-77-236-77h-302c-12 0-23 10-23 23v144c0 12 11 23 23 23h304c40 0 75 14 106 42 30 27 44 60 44 100 0 42-14 78-43 108-30 31-64 46-105 46h-260c-13 0-23 11-23 24v144c0 12 10 23 23 23h268c55 0 102 17 140 50s56 76 56 132c0 52-20 93-60 126-38 31-83 47-136 47h-314c-12 0-23 10-23 23v144c0 12 11 23 23 23h315c105 0 197-31 274-92 85-68 129-157 129-264 0-74-22-143-64-204-32-46-71-80-117-99z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M12041 469H12945V1692H12041z'
                className='BoundingBox'
              ></path>
              <path
                fill='#DB1921'
                d='M12921 469h-453c-113 0-212 36-294 106-89 76-133 173-133 288 0 106 36 195 108 266 56 56 128 95 215 115l-319 409c-5 7-6 17-2 25s12 13 20 13h181c8 0 14-4 19-10l424-582c5-7 6-16 2-24s-11-13-20-13h-198c-63 0-116-17-158-52-44-36-65-85-65-147 0-61 22-109 67-148 42-37 94-56 156-56h275v1009c0 12 10 23 22 23h153c12 0 22-11 22-23V492c0-13-10-23-22-23z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M13114 469H14177V1692H13114z'
                className='BoundingBox'
              ></path>
              <path
                fill='#DB1921'
                d='M14153 469h-152c-7 0-14 3-18 9l-671 901V492c0-13-10-23-23-23h-153c-12 0-22 10-22 23v1176c0 12 10 23 22 23h151c7 0 14-4 18-10l672-913v900c0 12 11 23 24 23h152c12 0 23-11 23-23V492c0-13-11-23-23-23z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M14344 469H15407V1692H14344z'
                className='BoundingBox'
              ></path>
              <path
                fill='#DB1921'
                d='M15383 469h-152c-13 0-23 10-23 23v463h-666V492c0-13-10-23-23-23h-152c-13 0-23 10-23 23v1176c0 12 10 23 23 23h152c13 0 23-11 23-23v-522h666v522c0 12 10 23 23 23h152c13 0 23-11 23-23V492c0-13-10-23-23-23z'
              ></path>
            </g>
            <g className='com.sun.star.drawing.ClosedBezierShape'>
              <path
                fill='none'
                d='M0 35H2570V2348H0z'
                className='BoundingBox'
              ></path>
              <path
                fill='#005694'
                d='M1140 1037c-20 0-36 15-36 35v258c0 20 16 36 36 36h325c20 0 37-16 37-36v-258c0-20-17-35-37-35h-325zm363-392v182h642c74 0 77 1 146 29 10 3 19 9 28 14 74 42 132 101 176 171 48 77 74 164 74 253 0 295-268 510-565 510-21 0-41-1-61-3 1 12 1 24 1 36 0 295-268 510-566 510-336 0-517-225-518-534v-83h-1v-693H634c-148 1-262-15-348 123-26 41-40 87-40 135 0 165 156 280 320 280 22 0 44-2 66-5l11-2v233l-8 1c-23 2-46 3-69 3-297 0-566-215-566-510 0-89 27-176 75-252 60-96 142-168 252-207 6-2 12-4 18-5-8-11-15-22-22-33-48-77-74-163-74-253 0-295 268-510 565-510 231 0 407 108 481 313 74-205 251-313 482-313 297 0 565 215 565 510 0 31-3 62-9 92l-1 8h-255l4-12c11-29 16-58 16-88 0-165-156-279-320-279-203 0-272 130-273 304v75h-1zm-398 934v233c1 174 70 305 273 305 165 0 321-115 321-280 0-48-14-94-40-136-91-143-213-122-367-122h-187zm625-543l1 233c1 174 70 305 273 305 164 0 320-115 320-280 0-48-14-94-40-136-91-143-213-122-366-122h-188zm-627-210l-1-243c0-175-80-317-288-317-169 0-320 124-320 291 0 50 14 98 40 141 90 149 210 128 366 128h203z'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Icon
