import React from 'react'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { formatMoney } from '../../core/tools/tools'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  table: {
    // minWidth: 650
  },
  tableHead: {
    fontWeight: 'bold'
  },
  tableRow: {
    backgroundColor: 'inherit',
    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey[50]
    }
  }
}))

export default function TableComponent({ month }) {
  const classes = useStyles()
  const totals = {
    balance_start: 0,
    accrued: 0,
    penalties: 0,
    paid: 0,
    balance_end: 0
  }
  return (
    <Table className={classes.table} aria-label='simple table'>
      <TableBody>
        <TableRow>
          {/* <TableCell className={classes.tableHead}>Услуга</TableCell> */}
          {/* <TableCell align='right' className={classes.tableHead}>
              Начальный баланс
            </TableCell> */}
          {/* <TableCell align='right' className={classes.tableHead}>
            Начислено
          </TableCell> */}
          {/* <TableCell align='right' className={classes.tableHead}>
              Оплачено
            </TableCell>
            <TableCell align='right' className={classes.tableHead}>
              Конечный баланс
            </TableCell> */}
        </TableRow>
        {month.map(row => {
          const balance_start = parseFloat(
            get(row, 'attributes.field_balance_start')
          )
          const accrued = parseFloat(get(row, 'attributes.field_accrued'))
          const penalties =
            parseFloat(
              get(row, 'attributes.field_penalties_accrued', 0)
            ) +
            parseFloat(
              get(row, 'attributes.field_penalties_recalculation', 0)
            )
          const paid = parseFloat(get(row, 'attributes.field_paid'))
          const balance_end = parseFloat(
            get(row, 'attributes.field_balance_end')
          )
          totals.balance_start += balance_start
          totals.accrued += accrued
          totals.penalties += penalties
          totals.paid += paid
          totals.balance_end += balance_end
          return (
            <TableRow className={classes.tableRow} key={get(row, 'id')}>
              <TableCell component='th' scope='row'>
                {get(row, 'included.field_service.attributes.title')}
              </TableCell>
              {/* <TableCell align='right' component='th' scope='row'>
                  {formatMoney(balance_start)}
                </TableCell> */}
              <TableCell align='right' component='th' scope='row'>
                {formatMoney(accrued)}
              </TableCell>
              {/* <TableCell align='right' component='th' scope='row'>
                  {formatMoney(paid)}
                </TableCell>
                <TableCell align='right' component='th' scope='row'>
                  {formatMoney(balance_end)}
                </TableCell> */}
            </TableRow>
          )
        })}
        <TableRow className={classes.tableRow}>
          <TableCell component='th' scope='row'>
            Пени
          </TableCell>
          <TableCell align='right' component='th' scope='row'>
            {formatMoney(totals.penalties)}
          </TableCell>
        </TableRow>
        <TableRow key={month.length}>
          <TableCell className={classes.tableHead} component='th' scope='row'>
            Итого
          </TableCell>
          {/* <TableCell
              align='right'
              className={classes.tableHead}
              component='th'
              scope='row'
            >
              {formatMoney(totals.balance_start)}
            </TableCell> */}
          <TableCell
            align='right'
            className={classes.tableHead}
            component='th'
            scope='row'
          >
            {formatMoney(totals.accrued + totals.penalties)}
          </TableCell>
          {/* <TableCell
              align='right'
              className={classes.tableHead}
              component='th'
              scope='row'
            >
              {formatMoney(totals.paid)}
            </TableCell>
            <TableCell
              align='right'
              className={classes.tableHead}
              component='th'
              scope='row'
            >
              {formatMoney(totals.balance_end)}
            </TableCell> */}
        </TableRow>
      </TableBody>
    </Table>
  )
}

TableComponent.propTypes = {
  month: PropTypes.array.isRequired
}