import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Tab, Tabs, Grid, Button } from '@material-ui/core'
import get from 'lodash/get'
import Header from './header'
import Bills from '../core/components/bills'
import BillsPageView from './bills/bills-page'
import Payments from '../core/components/payments'
import PaymentsPageView from './payments/payments-page'
import Dashboard from './dashboard/dashboard'
import { useToken } from '../core/hooks/token'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined'
import Faq from './faq/faq'
import Ipu from './ipus/ipus-page'
import Payment from './payment/payment'
import AddAppeal from '../core/components/add-appeal'
import AddAppealForm from './add-appeal-form/add-appeal-form'
import { ReactComponent as CounterIcon } from '../assets/images/2.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabsWrapper: {
    marginBottom: theme.spacing(4),
  },
  tabWrapper: {
    fontSize: '1rem',
    textTransform: 'none',
    fontWeight: '600',
  },
  headerLeftCol: {
    flex: 1,
  },
  headerRightCol: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}))

export default () => {
  const token = useToken()
  if (!token) {
    window.location.href = '/signin'
  }
  const classes = useStyles()
  const [tab, setTab] = React.useState(0)
  const account = useSelector((state) => state.main.currentAccount)
  const isIpu = parseInt(
    get(account, 'included.field_house.attributes.field_is_ipu')
  )

  const [openAppealForm, setOpenAppealForm] = useState(false)

  const handleChange = (event, newTab) => {
    setTab(newTab)
  }
  let { path, url } = useRouteMatch()

  const cityTitle = get(account, 'included.field_area.attributes.title')
  const roomType = get(
    account,
    'included.field_account.attributes.field_room_type'
  )
  const accountTitle = get(account, 'included.field_account.attributes.title')
  const roomNumber = get(
    account,
    'included.field_account.attributes.field_room_number'
  )
  const houseTitle = get(account, 'included.field_house.attributes.title')
  const firmTitle = get(account, 'included.field_firm.attributes.title')

  return (
    <Box className={classes.root}>
      <Header>
        <Grid container>
          <Box className={classes.headerLeftCol}>
            <h2>
              {cityTitle}, {houseTitle} {roomType}&nbsp;{roomNumber}
            </h2>
            <Typography variant='body1' className={classes.fullAddress}>
              ЛС:&nbsp;{accountTitle}, {firmTitle}
            </Typography>
          </Box>
          <Box className={classes.headerRightCol}>
            <Button
              onClick={() => {
                setOpenAppealForm(true)
              }}
              color='primary'
              variant='outlined'
              size='large'
            >
              Написать обращение
            </Button>
            <AddAppeal open={openAppealForm}>
              <AddAppealForm
                open={openAppealForm}
                setOpen={setOpenAppealForm}
              />
            </AddAppeal>
          </Box>
        </Grid>
      </Header>
      <Box className={classes.tabsWrapper}>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='on'
        >
          <Tab
            icon={<DashboardOutlinedIcon />}
            classes={{
              wrapper: classes.tabWrapper,
            }}
            component={Link}
            to={`${url}`}
            label='Рабочий стол'
          />
          <Tab
            icon={<EventNoteOutlinedIcon />}
            classes={{
              wrapper: classes.tabWrapper,
            }}
            component={Link}
            to={`${url}/bills`}
            label='Начисления'
          />
          <Tab
            icon={<EventAvailableOutlinedIcon />}
            classes={{
              wrapper: classes.tabWrapper,
            }}
            component={Link}
            to={`${url}/payments`}
            label='Платежи'
          />
          <Tab
            icon={<CreditCardIcon />}
            classes={{
              wrapper: classes.tabWrapper,
            }}
            component={Link}
            to={`${url}/payment`}
            label='Оплатить'
          />
          {isIpu && (
            <Tab
              icon={
                <CounterIcon
                  style={{
                    fill: 'currentColor',
                    width: '1em',
                    height: '1em',
                    display: 'inline-block',
                    fontSize: '1.5rem',
                    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    flexShrink: 0,
                  }}
                />
              }
              classes={{
                wrapper: classes.tabWrapper,
              }}
              component={Link}
              to={`${url}/ipu`}
              label='Показания ИПУ'
            />
          )}
          <Tab
            icon={<HelpOutlineIcon />}
            classes={{
              wrapper: classes.tabWrapper,
            }}
            component={Link}
            to={`${url}/faq`}
            label='Вопросы и ответы'
          />
        </Tabs>
      </Box>
      <Switch>
        <Route path={`${path}/bills`}>
          <Bills>
            <BillsPageView tab={tab} setTab={setTab} />
          </Bills>
        </Route>
        <Route path={`${path}/payments`}>
          <Payments>
            <PaymentsPageView tab={tab} setTab={setTab} />
          </Payments>
        </Route>
        <Route path={`${path}/payment`}>
          <Payment tab={tab} setTab={setTab} />
        </Route>
        {isIpu && (
          <Route path={`${path}/ipu`}>
            <Ipu tab={tab} setTab={setTab} />
          </Route>
        )}
        <Route path={`${path}/faq`}>
          <Faq tab={tab} setTab={setTab} />
        </Route>
        <Route path={`${path}/`}>
          <Dashboard />
        </Route>
      </Switch>
    </Box>
  )
}
