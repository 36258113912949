import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Typography,
  Grid,
  Paper,
  Button,
  LinearProgress,
  Fade,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatMoney } from '../../core/tools/tools'
import { Formik, Form, Field } from 'formik'
import { useDataProvider } from '../../core/hooks/dataProvider'
import * as Yup from 'yup'
import settings from '../../core/constants/settings'
import { isEmpty, get } from 'lodash'
import master from './mastercard_s.png'
import visa from './visa_s.png'
import mir from './mir_s.png'
import TextField from '../ui/textfield'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(5, 3),
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
  },
  sumWrapper: {
    display: 'flex',
  },
  rub: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    alignSelf: 'center',
    paddingLeft: theme.spacing(1),
  },
  inputWrapper: {
    flexGrow: 1,
  },
  buttonWrapper: {
    textAlign: 'center',
  },
  info: {
    marginTop: theme.spacing(5),
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
    verticalAlign: 'middle',
  },
  logoWrapper: {
    padding: theme.spacing(0, 1),
    alignSelf: 'center',
  },
  paymentInfo: {
    margin: '5px 0px'
  }
}))

export default function Payment({ tab, setTab }) {
  if (tab !== 3) {
    setTab(3)
  }
  let toPay = useSelector((state) => state.account.toPay.toPaySum)
  toPay = toPay < 0 ? 0 : toPay
  const account = useSelector(
    (state) => state.main.currentAccount.included.field_account.attributes.title
  )
  const email = useSelector((state) => state.auth.user.current_user.email)

  const classes = useStyles()
  const schema = Yup.object().shape({
    sum: Yup.string().required('Заполние это поле'),
  })
  const [values, setValues] = useState({})
  const dataProvider = useDataProvider()

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const { baseURL } = settings
      const url = `${baseURL}/payment/get-url?account=${account}&email=${email}&amount=${values.sum}`
      const response = await dataProvider.get(url)
      const responseUrl = get(response, 'data.url')
      window.location.href = responseUrl
    }
    if (!isEmpty(values)) {
      fetchData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <Fade in={true}>
      <Paper className={classes.paperRoot}>
        <Grid container alignContent='center' justify='center'>
          <Grid item xs={8} sm={7} md={5} lg={4} className={classes.col1}>
            <Typography className={classes.header} variant='h6'>
              К оплате: {formatMoney(toPay)}
            </Typography>
            <Formik
              initialValues={{ sum: toPay }}
              validationSchema={schema}
              onSubmit={(values) => {
                setValues(values)
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form noValidate>
                  {isSubmitting && <LinearProgress />}
                  <Box className={classes.sumWrapper}>
                    <Box className={classes.inputWrapper}>
                      <Field
                        component={TextField}
                        label='Сумма'
                        type='number'
                        name='sum'
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        value={values.sum}
                        inputProps={{ step: '0.01' }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box className={classes.rub}>
                      <Typography>руб.</Typography>
                    </Box>
                  </Box>
                  <Box className={classes.paymentInfo}>
                    Информация об оплате отразится в
                    личном кабинете не позднее 7 дней
                  </Box>
                  <Box className={classes.buttonWrapper}>
                    <Button
                      type='submit'
                      // fullWidth
                      color='primary'
                      variant='contained'
                      size='large'
                      disabled={isSubmitting || values.sum <= 0}
                    >
                      Оплатить
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
            <Grid container className={classes.info}>
              <Grid item xs={6}>
                <Box>
                  <a
                    href='https://dmhzn.com/page/pravila-onlayn-oplaty'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Правила оплаты онлайн
                  </a>
                </Box>
                <Box>
                  <a
                    href='https://dmhzn.com/page/politika-vozvratov'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Политика возвратов
                  </a>
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.logoWrapper}>
                <img className={classes.logo} src={mir} alt='Mir' />
              </Grid>
              <Grid item xs={2} className={classes.logoWrapper}>
                <img className={classes.logo} src={master} alt='Master Card' />
              </Grid>
              <Grid item xs={2} className={classes.logoWrapper}>
                <img className={classes.logo} src={visa} alt='Visa' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  )
}

Payment.propTypes = {
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired
}