import React, { useEffect, cloneElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDataProvider } from '../hooks/dataProvider'
import { useParams } from 'react-router-dom'
import _, { get, set, uniqBy, isEmpty, padStart, find, round } from 'lodash'
import moment from 'moment'
import { requestTimeLimit } from '../tools/tools'

export default props => {
  const { children } = props
  const dataProvider = useDataProvider()
  const data = useSelector(state => state.account.payments)
  const year = useSelector(state => state.account.paymentsYear)
  const years = useSelector(state => state.account.paymentsYears)
  const { id } = useParams()
  const dispatch = useDispatch()
  const lastBill = useSelector((state) =>
    get(state, 'account.lastBill', { lastMonthDate: '' })
  )

  const { date: lastMonthDate } = lastBill

  const setYear = value => {
    if (value === year) return
    dispatch({
      type: 'ACCOUNT_PAYMENTS',
      payload: {},
    })
    dispatch({
      type: 'ACCOUNT_PAYMENTS_YEAR',
      payload: value,
    })
  }

  useEffect(() => {
    async function fetchYears() {
      const response = await dataProvider.get(`api/account-bills-years/${id}`)
      const data = get(response, 'data', [])
      const dataYears = uniqBy(data.map(item => get(item, 'field_date')))
      dispatch({
        type: 'ACCOUNT_PAYMENTS_YEARS',
        payload: dataYears,
      })
      if (dataYears.length) {
        setYear(dataYears[0])
      }
    }
    if (isEmpty(years)) {
      fetchYears()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchPayments() {
      // You can await here
      const response = await dataProvider.get(
        `jsonapi/node/payment_service` +
        `?filter[date][condition][path]=field_date` +
        `&filter[date][condition][operator]=BETWEEN` +
        `&filter[date][condition][value][]=${year}-01-01` +
        `&filter[date][condition][value][]=${year}-12-31` +
        `&filter[field_account.id][value]=${id}` +
        `&sort=-field_date`
      )
      const data = []
      _.forEach(get(response, 'data.data', {}), (item) => {
        const targetItem = find(data, ['attributes.field_date', item.attributes.field_date])
        const paid = parseFloat(item.attributes.field_paid)
        const penalties_paid = parseFloat(item.attributes.field_penalties_paid)

        if (!targetItem) {
          item.attributes.field_paid = paid
          item.attributes.field_penalties_paid = penalties_paid
          data.push(item)
        }
        else {
          targetItem.attributes.field_paid += paid
          targetItem.attributes.field_penalties_paid += penalties_paid
        }
      })
      const totals = {}
      for (let i = 1; i <= 12; i++) {
        const month = padStart(i, 2, '0')
        const date = moment(`${year}-${month}-01`).format('YYYY-MM-DD')
        totals[date] = 0
      }
      data.map(item => {
        const date = moment(get(item, 'attributes.field_date')).format(
          'YYYY-MM-01'
        )
        const penalties = get(item, 'attributes.field_penalties_paid')
        const paid = round(get(item, 'attributes.field_paid') + penalties, 2)
        const total = get(totals, date, 0)
        set(totals, date, total + paid)
        return paid
      })
      dispatch({
        type: 'ACCOUNT_PAYMENTS',
        payload: {
          totals,
          payments: data,
          requestTime: +new Date(),
        },
      })
    }

    if (!isEmpty(year) && (isEmpty(data) || +new Date() - data.requestTime > requestTimeLimit)) {
      fetchPayments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year])

  return <>{cloneElement(children, { data, year, setYear, years, lastMonthDate })}</>
}
