import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import createStore from './core/store'
import Layout from './components/layout'
import { PersistGate } from 'redux-persist/integration/react'
import localStorage from 'redux-persist/lib/storage'
import { createMuiTheme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { YMInitializer } from 'react-yandex-metrika'
import settings from './core/constants/settings'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#005694',
    },
    secondary: {
      main: '#db1921',
    },
    text: {
      primary: '#434343',
    },
  },
})

const { store, persistor } = createStore(localStorage)
function App() {
  return (
    <Box className='App'>
      <YMInitializer accounts={[settings.ymAccount]} version='1' />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <Layout />
            </Router>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </Box>
  )
}

export default App
