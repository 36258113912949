import React, { cloneElement } from 'react'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => {
    return {
        link: {
            textDecoration: 'none',
            display: 'flex',
            cursor: 'pointer',
        },
    }
})

export default function LinkToAccount({ children, setCurrentAccount, index, accountId }) {
    const history = useHistory()
    const classes = useStyles()

    return (
        <Box
            className={classes.link}
            key={index}
            onClick={() => {
                history.push(`/account/${accountId}`)
                setCurrentAccount(index)
            }}
        >
            {children}
        </Box>
    )
}

LinkToAccount.propTypes = {
    accountId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    setCurrentAccount: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
}