import React, { useEffect, cloneElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDataProvider } from '../hooks/dataProvider'
import { useParams } from 'react-router-dom'
import { get, find, groupBy, uniqBy, set, isEmpty, padStart } from 'lodash'
import moment from 'moment'
import { requestTimeLimit } from '../tools/tools'

export default props => {
  const { children } = props
  const dataProvider = useDataProvider()
  const { id } = useParams()
  const dispatch = useDispatch()
  const data = useSelector(state => state.account.accruals)
  const years = useSelector(state => state.account.accrualsYears)
  const year = useSelector(state => state.account.accrualsYear)
  const lastBill = useSelector((state) =>
    get(state, 'account.lastBill', { lastMonthDate: '' })
  )

  const { date: lastMonthDate } = lastBill

  const setYear = value => {
    if (value === year) return
    dispatch({
      type: 'ACCOUNT_ACCRUALS',
      payload: {},
    })
    dispatch({
      type: 'ACCOUNT_ACCRUALS_YEAR',
      payload: value,
    })
  }

  useEffect(() => {
    async function fetchYears() {
      const response = await dataProvider.get(`api/account-bills-years/${id}`)
      const data = get(response, 'data', [])
      const dataYears = uniqBy(data.map(item => get(item, 'field_date')))
      dispatch({
        type: 'ACCOUNT_ACCRUALS_YEARS',
        payload: dataYears,
      })
      if (dataYears.length) {
        setYear(dataYears[0])
      }
    }

    if (isEmpty(years)) {
      fetchYears()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchAccruals() {
      // You can await here
      const response = await dataProvider.get(
        `jsonapi/node/bill` +
        `?filter[date][condition][path]=field_date` +
        `&filter[date][condition][operator]=BETWEEN` +
        `&filter[date][condition][value][]=${year}-01-01` +
        `&filter[date][condition][value][]=${year}-12-31` +
        `&filter[field_account.id][value]=${id}` +
        `&include=field_service` +
        `&sort=-field_date`
      )
      const data = get(response, 'data.data', {})
      const included = get(response, 'data.included', {})
      const totals = {}
      for (let i = 1; i <= 12; i++) {
        const month = padStart(i, 2, '0')
        const date = moment(`${year}-${month}-01`).format('YYYY-MM-DD')
        totals[date] = 0
      }
      const normalized = data.map(item => {
        const serviceId = get(item, 'relationships.field_service.data.id')
        const field_service = find(included, { id: serviceId })
        item.included = {
          field_service,
        }
        const date = get(item, 'attributes.field_date')
        const accrued =
          parseFloat(get(item, 'attributes.field_accrued')) +
          parseFloat(
            get(item, 'attributes.field_recalculation')
          )
        set(item, 'attributes.field_accrued', accrued)
        const total = get(totals, date, 0)
        set(totals, date, total + accrued)
        return item
      })
      const groupped = groupBy(normalized, 'attributes.field_date')
      const curMonth = moment().format('YYYY-MM-01')
      delete groupped[curMonth]
      dispatch({
        type: 'ACCOUNT_ACCRUALS',
        payload: {
          totals,
          months: groupped,
          requestTime: +new Date(),
        },
      })
    }

    if (!isEmpty(year) && (isEmpty(data) || +new Date() - data.requestTime > requestTimeLimit)) {
      fetchAccruals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year])

  return <>{cloneElement(children, { data, year, setYear, years, lastMonthDate })}</>
}
