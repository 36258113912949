export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_CURRENT_ACCOUNT':
      return {
        ...state,
        currentAccount: action.payload,
      }
    case 'SET_PAGE_TITLE':
      return {
        ...state,
        pageTitle: action.payload,
      }
    case 'SET_PAGE_ID':
      return {
        ...state,
        pageId: action.payload,
      }
    case 'SET_SHOW_WELCOME_DATE':
      return {
        ...state,
        showWelcomeDate: action.payload,
      }
    case 'DELETE_ACCOUNT':
      return {
        ...state,
        deleteAccount: action.payload,
      }
    default:
      return state
  }
}
