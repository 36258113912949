import { dataProvider } from '../tools/data-provider'
import settings from '../constants/settings'
import get from 'lodash/get'

const { baseURL } = settings
const config = { baseURL, headers: {} }

const clearMessage = () => {
  return {
    type: 'REGISTER_MESSAGE',
    payload: {
      text: '',
      status: '',
    },
  }
}

export const userLogin = (name, pass) => {
  return (dispatch) => {
    dispatch(clearMessage())
    dispatch({ type: 'REGISTER_LOADING', payload: true })
    dataProvider(config)
      .post('user/login?_format=json', { name, pass })
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((e) => {
        dispatch({
          type: 'REGISTER_MESSAGE',
          payload: {
            text: get(e.response, 'data.message'),
            status: 'error',
          },
        })
      })
      .finally(() => {
        dispatch({ type: 'REGISTER_LOADING', payload: false })
      })
  }
}

export const userLogout = () => {
  return (dispatch) => {
    dataProvider(config)
      .get('user/logout?_format=json')
      .finally(() => {
        dispatch({ type: 'LOGOUT' })
        dispatch({ type: 'REGISTER_CLEAR', payload: '' })
      })
  }
}

export const checkLogin = () => {
  return (dispatch) => {
    dispatch(request(true))
    dataProvider(config)
      .get('user/login_status?_format=json')
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((e) => {
        dispatch(failure(e))
      })
      .finally(() => {
        dispatch(request(false))
      })
  }
}

function request(payload) {
  return { type: 'LOGIN_REQUEST', payload }
}
function success(payload) {
  return { type: 'LOGIN_SUCCESS', payload }
}
function failure(payload) {
  return { type: 'LOGIN_FAILURE', payload }
}
